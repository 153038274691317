import PropTypes from 'prop-types'

const nestedCanBeUndefined = PropTypes.oneOfType([() => null, PropTypes.object])

export default {
  store: PropTypes.object,
  children: PropTypes.element,
  pageName: PropTypes.string,
  dispatch: PropTypes.func,
  route: PropTypes.object,
  router: PropTypes.object,
  location: PropTypes.object,
  sharedMessage: PropTypes.shape({
    role: PropTypes.oneOf(['information', 'error', 'success']),
    title: PropTypes.string,
    message: PropTypes.string,
    isVisible: PropTypes.bool,
    isCloseable: PropTypes.bool,
  }),
  user: PropTypes.shape({
    logged_in: PropTypes.bool,
    user_id: PropTypes.number,
    party_id: PropTypes.number,
    email_address: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  signupProgress: PropTypes.shape({
    loading: PropTypes.bool,
    errors: nestedCanBeUndefined,
  }),
  resendConfirmEmailProgress: PropTypes.shape({
    loading: PropTypes.bool,
    errors: nestedCanBeUndefined,
  }),
  confirmUserProgress: PropTypes.shape({
    loading: PropTypes.bool,
    errors: nestedCanBeUndefined,
  }),
}
