/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Pagination, Spin, Empty } from 'antd'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { styles } from '@ten-x/fe-lib-ui2020'
import configment from '@ten-x/configment'

import KNOWLEDGE_CENTER from '../../constants/knowledge_center'
import WPProvider from '../../data_providers/drupal'
import RecentNewsArticles from '../knowledgeCenter/recentNewsArticles'
import BreadcrumbsNav from '../breadcrumbs'

const { withThemeProvider } = styles
export const SearchPage = () => {
  const [loading, setLoading] = useState(true)
  const [drupalData, setDrupalData] = useState()
  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const parameters = queryParams.get('searchTerm')

  useEffect(() => {
    setLoading(true)
    const getArticles = async () => {
      const searchApi = await WPProvider.searchArticles(
        parameters,
        currentPage,
        itemsPerPage
      )
      setDrupalData(searchApi)
      setPosts(searchApi.posts)
      setLoading(false)
    }

    getArticles()
  }, [currentPage, parameters])

  const handlePaginationChange = (page) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  const urlList = [
    {
      label: 'Search',
      href: `${configment.get(
        'KNOWLEDGE_CENTER_URL'
      )}/search?searchTerm=${parameters}`,
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>{KNOWLEDGE_CENTER.TITLE}</title>
        <meta name="description" content={KNOWLEDGE_CENTER.DESCRIPTION} />
        <link rel="canonical" href={KNOWLEDGE_CENTER.CANONICAL} />
      </Helmet>
      {loading ? (
        <Spin />
      ) : posts?.length > 0 ? (
        <>
          <BreadcrumbsNav urlList={urlList} showAsH1 />
          <Title>Search results for "{parameters}"</Title>
          <RecentNewsArticles posts={posts} />
          {drupalData.postCount >= itemsPerPage && (
            <>
              <StyledMobilePagination
                current={currentPage}
                onChange={handlePaginationChange}
                pageSize={itemsPerPage}
                simple
                total={Number(drupalData?.postCount)}
              />
              <StyledTabletDesktopPagination
                current={currentPage}
                onChange={handlePaginationChange}
                pageSize={itemsPerPage}
                total={Number(drupalData?.postCount)}
              />
            </>
          )}
        </>
      ) : (
        <Empty description="We apologize, but it seems that your search didn't return any results. Our blog is a valuable resource, and we're here to assist you. Please consider refining your search query or explore our categories to discover relevant articles and information. If you have any questions or require further assistance, don't hesitate to contact our support team. We're committed to helping you find the information you need." />
      )}
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1100px;

  text-align: center;
  width: 100%;
  padding: 0 10px;
`

const Title = styled.h2`
  font-size: 20px;
  font-weight: 400;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 30px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 50px;
  }
`

const BasePagination = styled(Pagination)`
  margin-top: 20px;
`

const StyledMobilePagination = styled(BasePagination)`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: none;
  }
`

const StyledTabletDesktopPagination = styled(BasePagination)`
  display: none;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: block;
  }
`

export default withThemeProvider(SearchPage)
