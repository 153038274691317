import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import configment from '@ten-x/configment'
import sanitizeHtml from 'sanitize-html'

import SearchIcon from './search-icon.png'

// import WPProvider from '../../data_providers/wp'
// import customHooks from '../../utils/customHooks'

const Search = () => {
  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')
  const [autoSuggest, setAutoSuggest] = useState([])
  const [isClicked, setIsClicked] = useState(false)

  const handleChange = (e) => {
    setSearchTerm(e.target.value)
    // handleSearch(e.target.value) // COMMENT THIS OUT TO ENABLE AUTO SUGGEST
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit()
    }
  }

  const autoSuggestClick = (item) => {
    history.push(`${KNOWLEDGE_CENTER_URL}/search?searchTerm=${item.title}`)
    setSearchTerm('')
    setAutoSuggest([])
  }

  // const handleSearch = customHooks.useDebounce(async (term) => {
  //   const autoSuggest = await WPProvider.searchAutosuggestions(term)
  //   if (autoSuggest.length > 0) {
  //     setAutoSuggest(autoSuggest.map((item) => item))
  //   }
  // }, 500)

  const handleSearchSubmit = () => {
    setIsClicked(true)
    history.push(`${KNOWLEDGE_CENTER_URL}/search?searchTerm=${searchTerm}`)
    setTimeout(() => setIsClicked(false), 500) // Reset after animation
  }

  return (
    <>
      <SearchContainer>
        <SearchInputContainerMobile>
          <InputContainerMobile
            placeholder="Enter Topics"
            value={searchTerm}
            onChange={handleChange}
            onKeyUp={handleEnter}
          />
          <AutoSuggestContainerMobile>
            {autoSuggest.map((item) => (
              <div
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.title) }}
                key={item.id}
                onClick={() => autoSuggestClick(item)}
              />
            ))}
          </AutoSuggestContainerMobile>
        </SearchInputContainerMobile>
        <SearchInputContainerTabletDesktop>
          <InputContainerTabletDesktop
            placeholder="Enter keywords to search"
            value={searchTerm}
            onChange={handleChange}
            onKeyUp={handleEnter}
          />
          <AutoSuggestContainerTablet>
            {autoSuggest.map((item) => (
              <div
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.title) }}
                key={item.id}
                onClick={() => autoSuggestClick(item)}
              />
            ))}
          </AutoSuggestContainerTablet>
        </SearchInputContainerTabletDesktop>
        <StyledButton
          onClick={handleSearchSubmit}
          className={isClicked ? 'clicked' : ''}
        >
          <SearchText>Search</SearchText>
        </StyledButton>
      </SearchContainer>
    </>
  )
}

const SearchContainer = styled.div`
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  margin: 0 auto;
  max-width: 1300px;
  padding: 5px 5px 5px 15px;
  position: relative;
  width: 80%;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    min-height: 50px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin: auto 20px;
  }
`

const BaseInputContainer = styled.input`
  border: none;
  border-radius: 6px;
  height: 100%;
  margin: 0;
  padding: 5px;
  width: 100%;

  :focus-visible {
    outline: none;
  }
`

const InputContainerMobile = styled(BaseInputContainer)`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: none;
  }
`

const InputContainerTabletDesktop = styled(BaseInputContainer)`
  display: none;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: block;
  }
`

const StyledButton = styled.button`
  background-color: rgba(207, 19, 34, 1);
  border: 1px solid rgba(207, 19, 34, 1);
  border-radius: 20px;
  color: white;
  cursor: pointer;
  padding: 5px 40px;
  max-width: 184px;
  transition: all 0.3s;
  position: relative;
  padding-right: 70px;

  :hover {
    color: rgba(207, 19, 34, 1);
    background-color: white;
  }

  ::after {
    content: '';
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    transition: transform 0.5s linear;
  }

  &.clicked::after {
    animation: spin 0.5s linear;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    padding: 0;
    padding-right: 0;
    width: 40px;
  }

  @keyframes spin {
    from {
      transform: translateY(-50%) rotate(0deg);
    }
    to {
      transform: translateY(-50%) rotate(360deg);
    }
  }
`

const SearchText = styled.span`
  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    visibility: hidden;
  }
`

const SearchInputContainer = styled.div`
  flex-grow: 1;
  margin-right: 5px;
  position: relative;
  color: #000;
`

const SearchInputContainerMobile = styled(SearchInputContainer)`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: none;
  }
`

const SearchInputContainerTabletDesktop = styled(SearchInputContainer)`
  display: none;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: block;
  }
`

const BaseAutoSuggestContainer = styled.div`
  background-color: white;
  border-radius: 6px;
  left: 0;
  position: absolute;
  right: 0;
  top: 45px;

  > div {
    cursor: pointer;
    padding: 5px;
    :hover {
      background-color: #e6f7ff;
    }
  }
`

const AutoSuggestContainerMobile = styled(BaseAutoSuggestContainer)`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: none;
  }
`

const AutoSuggestContainerTablet = styled(BaseAutoSuggestContainer)`
  display: none;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: block;
  }
`

export default Search
