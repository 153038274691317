import { createStore, applyMiddleware, compose } from 'redux'

import rootReducer from './reducers/root'

// this is really cool, if you don't already know how it works, read up on it here:
// https://github.com/reactjs/redux/issues/99#issuecomment-112198579
//
// NOTE: For shared middleware, should we add to foundation so we can pull it in everywhere?
//
function promiseMiddleware() {
  return (next) => (action) => {
    const { promise, types, ...rest } = action
    if (!promise) {
      return next(action)
    }

    const [REQUEST, SUCCESS, FAILURE] = types
    next({ ...rest, type: REQUEST })
    return promise.then(
      // https://github.com/xjamundx/eslint-plugin-promise/issues/124
      // eslint-disable-next-line promise/no-callback-in-promise
      (value) => next({ ...rest, value, type: SUCCESS }),
      (error) => {
        // eslint-disable-next-line promise/no-callback-in-promise
        next({ ...rest, error, type: FAILURE })
        throw error
      }
    )
  }
}

export function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(promiseMiddleware))
  )
}

var store = null
export default function (initialState) {
  if (!initialState) return store
  store = configureStore(initialState)
  return store
}
