export const events = [
  {
    year: '2009',
    header: 'Held the First Online CRE Auction',
    statement:
      'Ten-X took its first step in transforming the commercial real estate transaction process by introducing online CRE auctions—combining access to the best commercial properties with the simplicity and security of an online transaction.',
  },
  {
    year: '2014',
    header: 'Received $50M CapitalG Investment',
    statement:
      'The power of the Ten-X marketplace was recognized through a $50M investment from CapitalG (formerly known as Google Capital) to continue striving to revolutionize the CRE space.',
  },
  {
    year: '2018',
    header: 'Surpassed $20B in Total Sales Volume',
    statement:
      'Following years of consistent growth, Ten-X established itself as a leader in the commercial real estate industry with tens of thousands of successfully completed transactions.',
  },
  {
    year: '2019',
    header: 'Powered 90% of All Online CRE Sales',
    statement:
      'As the largest online commercial real estate marketplace, the Ten-X auction technology has been the driving force behind a majority of transactions in the CRE space.',
  },
  {
    year: '2020',
    header: 'Acquired by CoStar Group',
    statement:
      'The leading platform for online commercial real estate auctions combined its strengths with the leading provider of commercial real estate information, analytics and online marketplaces to create an even more valuable transaction experience for brokers, owners and investors.',
  },
  {
    year: '2022',
    header: 'Introduced the Battle of the Bids',
    statement:
      'Ten-X, CoStar, and LoopNet teamed up to bring gamification into the commercial real estate industry through Battle of the Bids. The largest competition in CRE, this game brought together 13,000 brokers across the nation to compete to guess the sale price for Ten-X auction properties.',
  },
  {
    year: '2025',
    header: 'Integrated with LoopNet',
    statement:
      'By bringing the #1 transaction technology powering online CRE sales to the worlds largest commercial real estate market, Ten-X and LoopNet are simplifying the way investors underwrite, bid, and buy.',
  },
]

export const HEADER = {
  TITLE: 'REVOLUTIONIZING THE WORLD OF CRE',
  DESCRIPTION:
    'Since 2009, our technology has completely transformed the commercial real estate transaction process. Our focus remains fixed on driving new innovations and continuing to bring speed and certainty to brokers, owners, and investors.',
  SIZE: '58',
}
