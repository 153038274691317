/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Pagination, Spin, Empty } from 'antd'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { styles } from '@ten-x/fe-lib-ui2020'
import configment from '@ten-x/configment'

import WPProvider from '../../data_providers/drupal'
import RecentNewsArticles from '../knowledgeCenter/recentNewsArticles'
import BreadcrumbsNav from '../breadcrumbs'
import CategoryTiles from '../knowledgeCenter/categoryTiles'
import { LABELS, CATEGORY_NAMES, CATEGORY_TYPE } from '../../constants/enums'
import KNOWLEDGE_CENTER from '../../constants/knowledge_center'

const { withThemeProvider } = styles
export const Category = () => {
  const { category } = useParams()
  const [loading, setLoading] = useState(true)
  const [drupalData, setDrupalData] = useState()
  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  let categoryName = category.replace('-', ' ') // for multiword categories such as success-stories

  if (categoryName === CATEGORY_NAMES.NEWS.toLowerCase()) {
    categoryName = LABELS.NEWS.toLowerCase()
  }

  if (categoryName === CATEGORY_NAMES.EDUCATION.toLowerCase()) {
    categoryName = LABELS.EDUCATION.toLowerCase()
  }

  useEffect(() => {
    setLoading(true)
    const getPosts = async () => {
      const categoryApi = await WPProvider.getArticleCategoryPosts(
        category.toLowerCase(),
        currentPage,
        itemsPerPage
      )

      if (categoryApi === undefined) {
        setPosts({})
      } else {
        setDrupalData(categoryApi)
        setPosts(categoryApi.posts)
      }
      setLoading(false)
    }
    getPosts()
  }, [currentPage])

  let title
  let description
  if (category) {
    switch (category) {
      case CATEGORY_TYPE.EDUCATION:
        title = KNOWLEDGE_CENTER.EDUCATION_TITLE
        description = KNOWLEDGE_CENTER.EDUCATION_DESCRIPTION
        break
      case CATEGORY_TYPE.INSIGHTS:
        title = KNOWLEDGE_CENTER.INSIGHTS_TITLE
        description = KNOWLEDGE_CENTER.INSIGHTS_DESCRIPTION
        break
      case CATEGORY_TYPE.NEWS:
        title = KNOWLEDGE_CENTER.NEWS_TITLE
        description = KNOWLEDGE_CENTER.NEWS_DESCRIPTION
        break
      case CATEGORY_TYPE.SUCCESS_STORIES:
        title = KNOWLEDGE_CENTER.SUCCESS_STORIES_TITLE
        description = KNOWLEDGE_CENTER.SUCCESS_STORIES_DESCRIPTION
        break
      default:
        break
    }
  }

  const handlePaginationChange = (page) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  // If we want to customize prev, next or page buttons
  // const itemRender = (current, type, originalElement) => {
  //   if (type === 'prev') {
  //     return <a>Previous</a>
  //   }
  //   if (type === 'next') {
  //     return <a>Next</a>
  //   }
  //   if (type === 'page') {
  //     return <div>test</div>
  //   }
  //   return originalElement
  // }

  return (
    <Container>
      <Helmet>
        <title>{title || KNOWLEDGE_CENTER.TITLE}</title>
        <meta name="description" content={description} />
        <link
          rel="canonical"
          href={`https://www.ten-x.com/knowledge-center/${category.toLowerCase()}`}
        />
      </Helmet>
      {loading ? (
        <Spin />
      ) : posts?.length > 0 ? (
        <>
          <BreadcrumbsNav
            urlList={[
              {
                label: categoryName,
                href: `${configment.get(
                  'KNOWLEDGE_CENTER_URL'
                )}/${category.toLowerCase()}`,
              },
            ]}
            showAsH1
          />
          <CategoryTiles
            currentCategoryName={categoryName}
            disableOpaque={false}
          />
          <RecentNewsArticles
            posts={posts}
            title={
              categoryName === LABELS.NEWS.toLowerCase()
                ? 'RECENT NEWS'
                : `RECENT ${categoryName.toUpperCase()}`
            }
            category={category.toLowerCase()}
            showEducationVideos={
              category === CATEGORY_NAMES.EDUCATION.toLowerCase()
            }
          />
          {drupalData.postCount >= itemsPerPage && (
            <>
              <StyledMobilePagination
                current={currentPage}
                // itemRender={itemRender}
                onChange={handlePaginationChange}
                pageSize={itemsPerPage}
                simple
                total={Number(drupalData?.postCount)}
              />
              <StyledTabletDesktopPagination
                current={currentPage}
                // itemRender={itemRender}
                onChange={handlePaginationChange}
                pageSize={itemsPerPage}
                total={Number(drupalData?.postCount)}
              />
            </>
          )}
        </>
      ) : (
        <Empty description="No Articles" />
      )}
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1100px;

  text-align: center;
  width: 100%;
  padding: 0 10px;
`

const BasePagination = styled(Pagination)`
  margin-top: 20px;
`

const StyledMobilePagination = styled(BasePagination)`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: none;
  }
`

const StyledTabletDesktopPagination = styled(BasePagination)`
  display: none;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: block;
  }
`

export default withThemeProvider(Category)
