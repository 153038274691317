/* istanbul ignore file */
import React from 'react'
import Sdk from '@ten-x/fe-lib-sdk'

const listingAPI = [
  {
    title: 'Get Bid box listing Info',
    sdkFunction: Sdk.auctionListing.getListing,
    description: (
      <p>
        Requests for the Auction / Bidding process related listing information.{' '}
        <br />
        This includes: <br />
        - Number of bids, starting and highest Bid amounts <br />
        - Highest Bidder information <br />
        - Contact information <br />
        - Listing status and location <br />
      </p>
    ),
    params: ['Listing Id'],
  },
  {
    title: 'Get Server time Info',
    sdkFunction: Sdk.auctionListing.getServerTime,
    description: (
      <p>
        Returns the following data: <br />
        - The current date-time from the server (in UTC) <br />- The time offset
        difference between current local time and server time <br />
      </p>
    ),
    params: [],
  },
]

const bidderAPI = [
  {
    title: 'Place Bid (Requires being logged in)',
    sdkFunction: Sdk.bidder.placeBid,
    description: (
      <p>
        Places a bid on a listing for a specific amount. <br />
        Make sure the current user is able to place bids on the listing before
        using this. <br />
        Expects a bid response object as a result.
      </p>
    ),
    params: ['Listing Id', 'Bid Amount'],
  },
]

const registrationAPI = [
  {
    title: 'Get Bidder registration (Requires being logged in)',
    sdkFunction: Sdk.bidderRegistration.getRegistration,
    description: (
      <p>
        Get the bidder registration status of the currently logged <br />
        in user (inside the tenx iframe) for a particular listing. <br />
        Users need to be registered and approved as bidders for particular{' '}
        <br />
        listings in order to participate in the auctions (place bids) <br />
        This will return undefined if there's no registration, which <br />
        means the user is not registered yet; or this will return an error
        object if there's no logged in user
      </p>
    ),
    params: ['Listing Id'],
  },
  {
    title: 'Get Bidder registrations (Requires being logged in)',
    sdkFunction: Sdk.bidderRegistration.getRegistrations,
    description: (
      <p>
        Get the active bidder registrations of the currently logged-in <br />
        user. Users need to be registered and approved as bidders. <br />
        listings in order to participate in the auctions (place bids) <br />
        This will return an empty array if there's no registration, which <br />
        means the user is not registered to any listing yet; <br />
        or this will return an error object if there's no logged in user
      </p>
    ),
    params: [],
  },
  {
    title: 'Start a Bidder Registration process (Requires being logged in)',
    sdkFunction: Sdk.bidderRegistration.add,
    description: (
      <p>
        Start the registration flow for the specified listingId <br />
        (if needed). <br />
        Returns the new registration object or the <br />
        pre-existing one if the user was already registered
      </p>
    ),
    params: ['Listing Id'],
  },
  {
    title: 'Finalize bidder registration (Requires being logged in)',
    sdkFunction: Sdk.bidderRegistration.submit,
    description: (
      <p>
        Finalize the bidder registration process and change the STATUS <br />
        of all PENDING registrations to IN_PROCESS for the currently <br />
        logged-in user. <br />
        While on this status the registration will be under review by <br />
        the admin team. <br />
        This will return an error object if there's no logged in user or <br />
        if the registration information for one of the pending bidder <br />
        registrations is incomplete. <br />
      </p>
    ),
    params: [],
  },
  {
    title: 'Remove bidder registration (Requires being logged in)',
    sdkFunction: Sdk.bidderRegistration.remove,
    description: (
      <p>
        Delete the selected bidder registration by id for the currently <br />
        logged-in user. <br />
        This will return an error object if there's no logged in user <br />
        or if the "listingRegistrationId" is incorrect. <br />
      </p>
    ),
    params: ['Listing Registration Id'],
  },
  {
    title:
      "Get registration's broker representation (Requires being logged in)",
    sdkFunction: Sdk.bidderRegistration.getBrokerRepresentation,
    description: (
      <p>
        Get the broker representation (recommendation) for the currently <br />
        logged-in user in the given listingId. <br />
        This will return undefined if there's no broker representation <br />
        for the selected listing and an error object if there's no logged <br />
        in user or if the listingId information is incorrect. <br />
      </p>
    ),
    params: ['Listing Id'],
  },
  {
    title:
      "Save registration's broker representation (Requires being logged in)",
    sdkFunction: Sdk.bidderRegistration.saveBrokerRepresentation,
    description: (
      <p>
        Update the given registration's broker representation for the <br />
        currently logged-in user. <br />
        This will return an error object if there's no logged in user <br />
        or if the broker representation information is incomplete/incorrect.{' '}
        <br />
      </p>
    ),
    params: [
      'Listing Registration Id',
      'Is Broker Requested',
      'Broker Details',
    ],
    paramParsers: [null, null, JSON.parse],
  },
  {
    title: "Get registration's ContractIssueTo data (Requires being logged in)",
    sdkFunction: Sdk.bidderRegistration.getContractIssueTo,
    description: (
      <p>
        Get ContractIssueTo details of the currently logged-in user for a <br />
        given listing. <br />
        This will return an error object if there's no logged in user <br />
        or if the listingId information is incorrect. <br />
      </p>
    ),
    params: ['Listing Id'],
  },
  {
    title: "Get bidder's contract holders (Requires being logged in)",
    sdkFunction: Sdk.bidderRegistration.getContractHolders,
    description: (
      <p>
        Get the previously used Contract Holders of a given type <br />
        for the currently logged-in user. <br />
        This will return an error object if there's no logged in user <br />
        or if the "contractHolderType" is incorrect. <br />
      </p>
    ),
    params: ['Contract Holder Type ("BUYER", "CO_BUYER)'],
  },
  {
    title: "Get a bidder's contract holder by id (Requires being logged in)",
    sdkFunction: Sdk.bidderRegistration.getContractHolder,
    description: (
      <p>
        Get the details of a previously used Contract Holder of a given <br />
        type by it's id for the currently logged-in user. <br />
        This will return an error object if there's no logged in user <br />
        or if the "holderType" or "holderId" are incorrect. <br />
      </p>
    ),
    params: ['Holder Type ("INDIVIDUAL", "ENTITY)', 'Holder Id'],
  },
  {
    title:
      "Save registration's contract issue to data (Requires being logged in)",
    sdkFunction: Sdk.bidderRegistration.saveContractIssueTo,
    description: (
      <p>
        Update the given registration's Contract Issue To for the <br />
        currently logged-in user. <br />
        This will return an error object if there's no logged in user <br />
        or if the Contract Issue To information is incomplete/incorrect. <br />
      </p>
    ),
    params: ['Contract Issue To'],
    paramParsers: [JSON.parse],
  },
]

const authAPI = [
  {
    title: 'Get Ten-X Token for the user',
    sdkFunction: Sdk.auth.getAuthenticationToken,
    description: (
      <p>
        Get the Encrypted Ten-X access token that will enable users to make
        realtime requests as authenticated Ten-X users without needing cookies
        or other session information
      </p>
    ),
    params: [],
  },
]

const configAPI = [
  {
    title: 'Set Event source Id for requests',
    sdkFunction: (sourceId) => {
      Sdk.config.setEventSourceId(sourceId)
      return `Event source Id set to : ${Sdk.config.getEventSourceId()}`
    },
    description: (
      <p>
        Sets the EventSourceId value that will be sent as a request header on
        all REST Api calls.
      </p>
    ),
    params: ['Source Id'],
  },
]

export default {
  listingAPI,
  bidderAPI,
  registrationAPI,
  authAPI,
  configAPI,
}
