/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { AuthModal, Header, Footer, styles, utils } from '@ten-x/fe-lib-ui2020'
import configment from '@ten-x/configment'
import ClassNames from 'classnames/bind'
import _get from 'lodash/get'

import Hero from '../../components/hero'
import TransactOnLoopnet from '../../components/transactOnLoopnet'
import types from '../../constants/prop_types'
import analytics from '../../services/analytics'
import cls from '../../scss/config.scss'

const cx = ClassNames.bind(cls)

const { withThemeProvider } = styles

const Main = styled.main`
  background-color: white;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${(props) => (props.showHero ? '30px' : '0px')};
  padding-bottom: ${(props) => (props.noSpacing ? 0 : '50px')};
  margin-left: auto;
  margin-right: auto;
  max-width: 120rem;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    padding-top: 0px;
  }
`

export const FixedHeader = styled(Header)`
  margin-bottom: -124px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-bottom: -132px;
  }
`

const SIGNUP_SUCCESS_QS_KEY = 'signup'

const Layout = ({
  featureFlags,
  urlListFooter,
  urlListHeader,
  user,
  location,
  children,
  heroType,
  showHeroText = false,
  showHero = true,
  showSearch = false,
  darkFont = false,
  noSpacing = false,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const queryParams = new URLSearchParams(location?.search)
  const urlList = { ...urlListFooter, ...urlListHeader }
  const isSignupSuccess = queryParams.get(SIGNUP_SUCCESS_QS_KEY) === 'SUCCESS'
  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')

  const [showSignupSuccessScreen] = useState(isSignupSuccess)
  const [authModalVisible, setAuthModalVisible] = useState(false)
  const [backToUrl, setBackToUrl] = useState(KNOWLEDGE_CENTER_URL)

  const isPoweredByTenX = _get(featureFlags, 'fe.cre.powered-by-tenx', true)
  const transparentMode = !(isPoweredByTenX && darkFont)

  function showLoginModal(redirect = KNOWLEDGE_CENTER_URL) {
    setBackToUrl(redirect)
    setAuthModalVisible(true)
  }

  function hideAuthModal() {
    setAuthModalVisible(false)
  }

  function onRegistrationFinish() {
    // We cannot use react-router here because we are going to
    // another application (we need to make an http request)
    window.location.href = urlListHeader?.search || KNOWLEDGE_CENTER_URL
  }

  function trackOnClick(tag) {
    analytics.onHomepageLinkClick(tag)
  }

  useEffect(() => {
    analytics.onPageLoadHome()
    utils.createUtmLocalStorage()
  }, [])

  let pathName
  if (typeof window !== 'undefined') {
    pathName = window.location.pathname
  }

  return (
    <div className={cx('marketing-blog-container')}>
      <FixedHeader
        transparentMode={transparentMode}
        featureFlags={featureFlags}
        urlList={urlList}
        user={user}
        showLoginModal={showLoginModal}
        showSearchBox
        transparentDarkFont={darkFont}
        pathName={pathName}
      />
      {showHero && (
        <Hero
          showHeroText={showHeroText}
          showSearch={showSearch}
          heroImage={heroType?.IMAGE}
          heroText={heroType?.TEXT}
          heroVideo={heroType?.VIDEO}
          demoButton={heroType?.DEMO_BUTTON}
        />
      )}
      <AuthModal
        showSignupSuccessScreen={showSignupSuccessScreen}
        visible={authModalVisible}
        onCancel={hideAuthModal}
        onRegistrationFinish={onRegistrationFinish}
        backTo={backToUrl}
      />
      <Main showHero={showHero} noSpacing={noSpacing}>
        {children}
      </Main>
      <TransactOnLoopnet />
      <Footer
        urlList={urlList}
        trackOnClick={trackOnClick}
        featureFlags={featureFlags}
      />
    </div>
  )
}

Layout.propTypes = {
  user: types.user.isRequired,
  featureFlags: PropTypes.object.isRequired,
  urlListFooter: PropTypes.object.isRequired,
  urlListHeader: PropTypes.object.isRequired,
  location: PropTypes.object,
  children: PropTypes.element.isRequired,
  showHeroText: PropTypes.bool,
  showHero: PropTypes.bool,
  showSearch: PropTypes.bool,
  darkFont: PropTypes.bool,
  noSpacing: PropTypes.bool,
  heroType: PropTypes.object,
}

Main.propTypes = {
  showHero: PropTypes.bool,
  noSpacing: PropTypes.bool,
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
    featureFlags: store.config.featureFlags,
    urlListFooter: store.config.uiUrls && store.config.uiUrls.footer,
    urlListHeader: store.config.uiUrls && store.config.uiUrls.header,
  }
}

const connectedLayout = connect(mapStateToProps)(Layout)
export default withThemeProvider(connectedLayout)
