export const BANNERS = {
  TRANSACTION_TECH: {
    TITLE: 'THE #1 ONLINE CRE TRANSACTION TECHNOLOGY',
    DESCRIPTION:
      'We combine the largest and most trusted CRE marketplace with industry-leading auction technology and best-in-class data to make transactions twice as fast and twice as certain for brokers, owners, and investors.',
    SIZE: '55',
  },
  OWNERS: {
    TITLE: 'OWNERS OF ALL SIZES SEARCH LOOPNET AUCTIONS EVERY MONTH',
    IMAGES: [
      { src: '/images/homepage/logos/kimco.png', alt: 'Kimco' },
      {
        src: '/images/homepage/logos/starwood_capital_group.png',
        alt: 'Starwood Group',
      },
      { src: '/images/homepage/logos/prologis.png', alt: 'Prologis' },
      { src: '/images/homepage/logos/clarion_partners.png', alt: 'Clarion' },
      { src: '/images/homepage/logos/blackrock.png', alt: 'BlackRock' },
      { src: '/images/homepage/logos/eq_office.png', alt: 'EQ Office' },
    ],
    SIZE: '35',
  },
  BROKERS: {
    TITLE: 'BROKERS OF ALL SIZES SEARCH LOOPNET AUCTIONS EVERY MONTH',
    IMAGES: [
      {
        src: '/images/homepage/logos/cbre.png',
        alt: 'CBRE',
      },
      {
        src: '/images/homepage/logos/colliers.png',
        alt: 'Colliers',
      },
      {
        src: '/images/homepage/logos/jll.png',
        alt: 'JLL',
      },
      {
        src: '/images/homepage/logos/newmark.png',
        alt: 'Newmark',
      },
      {
        src: '/images/homepage/logos/cushman_wakefield.png',
        alt: 'Cushman & Wakefield',
      },
      {
        src: '/images/homepage/logos/nai_global.png',
        alt: 'Global',
      },
    ],
    SIZE: '35',
  },
}

export const STATISTICS = {
  OWNERS: {
    DESCRIPTION: {
      title: 'Owners',
      content: `Our partnership with owners in commercial real estate drives the
              success of our online auctions. From listing to closing, our team
              works closely with you and your broker to help you meet your
              goals, make informed decisions, and adapt to market changes. The
              result? Faster closings and higher prices.`,
    },
    STATS: [
      { value: '$34B', label: 'Total Sold' },
      { value: '60%', label: 'Trade Rate' },
      { value: '118%', label: "Avg Seller's Reserve" },
      { value: '97 Days', label: 'Avg List to Close' },
    ],
  },
  BROKERS: {
    DESCRIPTION: {
      title: 'Brokers',
      content: `Our collaboration with brokers is essential to driving success in online auctions. From listing to closing, we work together with you and your clients to ensure goals are met, decisions are data-driven, and you're positioned to adapt to market changes. The result? Increased speed and certainty.`,
    },
    STATS: [
      { value: '11,500', label: 'Total Properties Sold' },
      { value: '1.6M', label: 'Total Leads Generated' },
      { value: '7,500', label: 'Broker Partners' },
      { value: '112%', label: 'Avg Closing Price Over Reserve' },
    ],
  },
}
