/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { Spin, Empty } from 'antd'
import { styles, utils } from '@ten-x/fe-lib-ui2020'
import configment from '@ten-x/configment'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'
import slugify from 'slugify'

import BreadcrumbsNav from '../breadcrumbs'
import WPProvider from '../../data_providers/drupal'
import { LABELS, CATEGORY_NAMES } from '../../constants/enums'
import KNOWLEDGE_CENTER from '../../constants/knowledge_center'
import { truncateSummary } from '../../utils/format'

import FacebookIcon from './facebook.svg'
import LinkedInIcon from './linkedin.svg'
import MailIcon from './mail.svg'
import PrintIcon from './print.svg'

const { withThemeProvider } = styles
const ArticlePage = () => {
  const [post, setPost] = useState()
  const [loading, setLoading] = useState(true)
  const [relatedPosts, setRelatedPosts] = useState([])
  const [relatedCategoryPosts, setRelatedCategoryPosts] = useState([])
  const [relatedEducationVideos, setRelatedEducationVideos] = useState([])
  const [categoryName, setCategoryName] = useState()
  const [categorySlug, setCategorySlug] = useState()
  const [categories, setCategories] = useState([])
  const [urlList, setUrlList] = useState([])
  const [articleUrl, setArticleUrl] = useState()

  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')
  const TENX_URL = configment.get('TENX_HOME_URL')

  const { category, slug, id } = useParams()

  let articleSlug
  let articleId = id

  // backward compatible with Wordpress using URL format: /knowledge-center/how-register-bid-ten-x/2342
  if (Number(slug)) {
    articleSlug = category
    articleId = Number(slug)
  } else {
    articleSlug = slug
  }

  const DRUPAL_ENDPOINT = configment.get('DRUPAL_BASE_URL')

  const title = post?.title || ''
  let articleBody = post?.field_article_body?.processed
  if (articleBody) {
    articleBody = articleBody
      .replaceAll('src="/sites/', `src="${DRUPAL_ENDPOINT}/sites/`)
      .replaceAll('href="/sites/', `href="${DRUPAL_ENDPOINT}/sites/`)
  }

  const body = articleBody
  const customAuthor = post?.field_article_custom_author
  const publishDate =
    post?.field_custom_publication_date &&
    moment(post?.field_custom_publication_date, 'YYYYMMDD').format(
      'MMMM Do, YYYY'
    )
  const date =
    new Date(post?.created)?.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }) || ''
  const articleImage = post?.images?.url
  const imageSrc = `${DRUPAL_ENDPOINT}${articleImage}` || ''

  // Used to add target blank and rel="noreferrer" to all anchor tags
  const contentRef = useRef()
  useLayoutEffect(() => {
    if (contentRef.current) {
      const aTags = contentRef.current.getElementsByTagName('a')
      if (aTags?.length > 0) {
        for (const item of aTags) {
          item.setAttribute('target', '_blank')
          item.setAttribute('rel', 'noreferrer')
        }
      }
    }
  })

  useEffect(() => {
    setLoading(true)

    const getArticle = async () => {
      const post = await WPProvider.getArticle(articleId, articleSlug)
      if (post?.data?.status === 404) {
        setPost(undefined)
      } else {
        setPost(post)
      }
      setLoading(false)
    }

    getArticle()
  }, [])

  useEffect(() => {
    if (categoryName && categorySlug) {
      let categoryLabel = categoryName
      let slug = post?.field_article_slug
      if (!slug) {
        slug = `${slugify(post?.title, { lower: true })}/${post?.id}`
      }
      const articleUrl = `${categorySlug}/${slug}`

      if (categoryName === CATEGORY_NAMES.EDUCATION) {
        categoryLabel = LABELS.EDUCATION
      }

      if (categoryName === CATEGORY_NAMES.NEWS) {
        categoryLabel = LABELS.NEWS
      }

      const urlList = [
        {
          label: categoryLabel,
          href: `${KNOWLEDGE_CENTER_URL}/${categorySlug}`,
        },
        {
          label: post?.title,
          href: `${KNOWLEDGE_CENTER_URL}/${articleUrl}`,
        },
      ]
      setUrlList(urlList)
      setArticleUrl(articleUrl)
    }
  }, [categoryName, categorySlug])

  useEffect(() => {
    if (post) {
      const getRelatedArticlesByCategory = async (id, categoryId) => {
        const relatedPosts = await WPProvider.getRelatedArticlesByCategory(
          id,
          categoryId
        )
        setRelatedCategoryPosts(relatedPosts)
      }

      const getRelatedArticlesByTags = async (id, tagIds) => {
        const relatedPosts = await WPProvider.getRelatedArticlesByTags(
          id,
          tagIds
        )
        setRelatedPosts(relatedPosts)
      }

      const getArticleEducationVideos = async (postId) => {
        const relatedPosts = await WPProvider.getArticleEducationVideos(postId)
        setRelatedEducationVideos(relatedPosts)
      }

      const getArticleCategory = async (categoryId, isEducationVideo) => {
        const categories = await WPProvider.getArticleCategories()
        setCategories(categories)

        // if isEducationVideo, get the categoryId based off the 'education' categorySlug
        if (isEducationVideo) {
          const matchingCategory = categories?.find(
            ({ attributes }) => attributes.field_slug === 'education'
          )
          if (matchingCategory) {
            categoryId = matchingCategory.id
          }
        }
        const category = await WPProvider.getArticleCategory(categoryId)

        setCategoryName(category?.attributes?.name)
        setCategorySlug(category?.attributes?.field_slug)
      }

      const categoryId =
        post?.field_article_category?.data?.id ||
        post?.field_article_category?.id
      const isEducationVideo =
        post?.node_type?.data?.meta?.drupal_internal__target_id ===
        'education_video'

      getArticleCategory(categoryId, isEducationVideo)

      const tagIds = []
      const tags = post?.field_article_tags?.data
      if (Array.isArray(tags)) {
        tags?.forEach((tag) => {
          tagIds.push(tag.id)
        })
      } else if (tags) {
        tagIds.push(tags.id)
      }
      if (tagIds.length) {
        getRelatedArticlesByTags(post?.id, tagIds)
      }
      if (isEducationVideo) {
        getArticleEducationVideos(post?.id)
      } else {
        getRelatedArticlesByCategory(post?.id, categoryId)
      }
    }
  }, [post])

  const StayInformed = () => {
    const href = `https://go.ten-x.com/KnowledgeSubscribe${utils.createPardotUtmFragment()}`

    return (
      <StayInformedContainer>
        <Title>Stay Informed</Title>
        <Subtitle>Subscribe to receive our auction news and insights</Subtitle>
        <SignUp href={href} rel="noreferrer" target="_blank">
          Sign Up
        </SignUp>
      </StayInformedContainer>
    )
  }

  const ShareIcons = () => {
    const shareLink = `${TENX_URL}${KNOWLEDGE_CENTER_URL}/${articleUrl}`
    return (
      <ShareIconContainer>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareLink}`}
          rel="noreferrer"
          target="_blank"
        >
          <img src={LinkedInIcon} />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
          rel="noreferrer"
          target="_blank"
        >
          <img src={FacebookIcon} />
        </a>
        <a onClick={() => window.print()}>
          <img src={PrintIcon} />
        </a>
        <a
          href={`mailto:someone@somewhere.com?subject=Check out this article on Ten-X&body=${shareLink}`}
        >
          <img src={MailIcon} />
        </a>
      </ShareIconContainer>
    )
  }

  // truncate the body and strip out any HTML tags, including the "Video Url"
  const description = body
    ? truncateSummary(
        sanitizeHtml(body || '', {
          allowedTags: [],
        })
      )
        .replace(/<[^>]*>?/gm, '')
        .replaceAll('\n', ' ')
        .replaceAll('Video Url', ' ')
    : ''

  return (
    <>
      <Container>
        <Helmet>
          <title>{title || KNOWLEDGE_CENTER.TITLE}</title>
          <meta name="description" content={description} />
          <link
            rel="canonical"
            href={`https://www.ten-x.com/knowledge-center/${categorySlug}/${articleSlug}`}
          />
        </Helmet>
        {loading ? (
          <Spin />
        ) : post ? (
          <>
            <ContentContainer>
              <RenderedContentContainer>
                <BreadcrumbsNav urlList={urlList} />
                <TitleContainer
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(title),
                  }}
                />
                <Divider />
                <DateAuthorShareContainer>
                  <div>
                    {publishDate || date ? (
                      <BaseParagraph>
                        Published: {publishDate || date}
                      </BaseParagraph>
                    ) : null}
                    <BaseParagraph>By {customAuthor || 'Ten-X'}</BaseParagraph>
                  </div>
                  <ShareIcons />
                </DateAuthorShareContainer>
                {imageSrc ? <ArticleImageContainer src={imageSrc} /> : null}
                <ArticleContainer
                  ref={contentRef}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(body, {
                      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                        'iframe',
                        'img',
                        'video',
                        'source',
                      ]),
                      allowedAttributes: false,
                      allowedIframeHostnames: [
                        'www.youtube.com',
                        'player.vimeo.com',
                      ],
                    }),
                  }}
                />
              </RenderedContentContainer>
              <RelatedContentContainer>
                <RelatedTitleContainer>
                  <h2>RELATED ARTICLES</h2>
                  <hr />
                </RelatedTitleContainer>
                {relatedPosts?.length > 0 ? (
                  relatedPosts.map((post) => (
                    <RelatedArticleItem
                      post={post}
                      key={post.id}
                      categories={categories}
                    />
                  ))
                ) : relatedCategoryPosts?.length > 0 ? (
                  relatedCategoryPosts.map((post) => (
                    <RelatedArticleItem
                      post={post}
                      key={post.id}
                      categories={categories}
                    />
                  ))
                ) : relatedEducationVideos?.length > 0 ? (
                  relatedEducationVideos.map((post) => (
                    <RelatedArticleItem
                      post={post}
                      key={post.id}
                      categories={categories}
                    />
                  ))
                ) : (
                  <Empty description="No Related Articles" />
                )}
                <StayInformed />
              </RelatedContentContainer>
            </ContentContainer>
          </>
        ) : (
          <Empty description="No Article" />
        )}
      </Container>
    </>
  )
}

const RelatedArticleItem = ({ post, categories }) => {
  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')
  let category

  const title = post?.title || ''
  const articleCategoryId =
    post?.field_article_category?.id || post?.field_article_category?.data?.id
  const matchingCategory = categories?.find(
    ({ id }) => id === articleCategoryId
  )
  if (matchingCategory) {
    category = matchingCategory?.attributes?.field_slug
  }

  let slug = post?.field_article_slug
  if (!slug) {
    slug = `${slugify(post?.title, { lower: true })}/${post?.id}`
  }
  const articleUrl = `${category}/${slug}`
  const publishDate =
    post?.field_custom_publication_date &&
    moment(post?.field_custom_publication_date, 'YYYYMMDD').format(
      'MMMM Do, YYYY'
    )
  const date =
    new Date(post?.revision_timestamp)?.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }) || ''
  const customAuthor = post?.field_article_custom_author

  return (
    <RelatedArticleContainer href={`${KNOWLEDGE_CENTER_URL}/${articleUrl}`}>
      <h3
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(title.split('--')[0]) }}
      />
      <p>Published: {publishDate || date}</p>
      <p>By {customAuthor || 'Ten-X'}</p>
    </RelatedArticleContainer>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  * {
    color: rgba(136, 136, 136, 1);
  }
`

const ContentContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: flex;
  }
`

const ArticleContainer = styled.div`
  .field__label.visually-hidden {
    display: none;
  }
`

const Divider = styled.hr`
  margin-left: 0;
  width: 75%;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    width: 100%;
  }
`

const TitleContainer = styled.h1`
  color: rgba(89, 89, 89, 1);
  font-size: 30px;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
  }
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 50px;
  }
`

const ArticleImageContainer = styled.img`
  margin: 20px 0;
  max-width: 100%;
`

const BaseParagraph = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const RenderedContentContainer = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 0 15px;

  img,
  video,
  iframe {
    max-width: 100%;
  }

  img {
    height: auto;
  }

  a {
    color: #1890ff;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex: 4;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const RelatedContentContainer = styled.div`
  padding: 0 10px;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    max-width: 360px;
    padding-left: 40px;
    flex: 2;
  }
`

const RelatedTitleContainer = styled.div`
  align-items: center;
  display: flex;
  h3 {
    color: rgba(89, 89, 89, 1);
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 15px;
    @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      font-size: 20px;
    }
  }
  hr {
    border-bottom: 1px solid rgba(153, 153, 153, 1);
    flex-grow: 1;
  }
`

const RelatedArticleContainer = styled.a`
  border-bottom: 1px solid rgba(153, 153, 153, 1);
  display: block;
  padding: 20px 0;

  h4 {
    font-size: 16px;
    font-weight: 400;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
`

const StayInformedContainer = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  min-height: 300px;
  padding: 20px;
  * {
    color: white;
  }
`
const Title = styled.p`
  font-size: 20px;
`
const Subtitle = styled.p`
  font-size: 16px;
`
const SignUp = styled.a`
  background-color: rgba(207, 19, 34, 1);
  border: 1px solid rgba(207, 19, 34, 1);
  color: white;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  transition: all 0.3s;
  :hover {
    color: rgba(207, 19, 34, 1);
    background-color: white;
  }
`

const ShareIconContainer = styled.div`
  display: flex;
  margin-top: 10px;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    justify-content: right;
  }
`

const DateAuthorShareContainer = styled.div`
  img {
    cursor: pointer;
    height: 20px;
    margin-right: 20px;
    width: 20px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    img {
      margin: 0;
      margin-left: 20px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    img {
      height: 35px;
      width: 35px;
    }
  }
`

const PostObject = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      rendered: PropTypes.string,
    }),
  ]),
  content: PropTypes.shape({
    rendered: PropTypes.string,
  }),
  excerpt: PropTypes.shape({
    rendered: PropTypes.string,
  }),
  sticky: PropTypes.bool,
}

RelatedArticleItem.propTypes = {
  post: PropTypes.shape(PostObject),
  categories: PropTypes.array,
}

export default withThemeProvider(ArticlePage)
