import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { utils } from '@ten-x/fe-lib-ui2020'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import configment from '@ten-x/configment'

import analytics from '../../services/analytics'
import { HEADING_TYPES } from '../../constants/enums'
import LandingPageTypes from '../../constants/landing_page_prop_types'

const pardotId = 'pardot-form'
const LandingPage = ({ PAGE_CONSTANTS, noForm, H1, pageType }) => {
  const {
    TITLE,
    DESCRIPTION,
    CANONICAL,
    HERO_TITLE,
    HERO_SUBTITLE,
    HERO_HEADING,
    HERO_PARAGRAPH,
    HERO_IMAGE,
    HERO_CUSTOMER_STATS,
    SECTION_2_TITLE,
    SECTION_2_PARAGRAPH,
    SECTION_2_IMAGE,
    SECTION_2_CUSTOMER_LOGOS_1,
    SECTION_2_CUSTOMER_LOGOS_2,
    SECTION_2_CUSTOMER_LOGOS_DESKTOP,
    SECTION_2_STRATEGY_IMAGE_MOBILE,
    SECTION_2_STRATEGY_IMAGE,
    SECTION_3_TITLE,
    SECTION_3_PARAGRAPH,
    SECTION_3_IMAGE,
    SECTION_3_ICON_SECTION,
    SECTION_4_TITLE,
    SECTION_4_IMAGE,
    PARDOT_DESCRIPTION,
  } = PAGE_CONSTANTS

  const DRUPAL_BASE_URL = configment.get('DRUPAL_BASE_URL')
  const DRUPAL_ENDPOINT = `${DRUPAL_BASE_URL}/sites/default/files`

  const [pardotUtmFragment, setPardotUtmFragment] = useState('')

  useEffect(() => {
    setPardotUtmFragment(utils.createPardotUtmFragment())
  }, [])

  useEffect(() => {
    analytics.onPageLoadLandingPage(pageType)
  }, [pageType])

  const PardotForm = () => {
    let pardotSource =
      HERO_TITLE === 'BROKERS'
        ? 'https://go.ten-x.com/l/667613/2022-06-30/45nj8'
        : 'https://go.ten-x.com/l/667613/2022-06-30/45njc'
    pardotSource += pardotUtmFragment
    return (
      <iframe
        allowtransparency="true"
        frameBorder="0"
        height="700"
        style={{ border: 0, width: '100%' }}
        src={pardotSource}
      />
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={DESCRIPTION} />
        <link rel="canonical" href={CANONICAL} />
      </Helmet>
      {/* HERO SECTION */}
      <Section
        topSection
        bgImage
        image={`${DRUPAL_ENDPOINT}/uploads/X_BACKGROUND.png`}
      >
        <ContentContainer>
          <FlexContent maxWidth={500} flexNum={6}>
            {H1 === HEADING_TYPES.HERO_SUBTITLE ? (
              <PageSubtitleH1>{HERO_SUBTITLE}</PageSubtitleH1>
            ) : (
              <PageSubtitle>{HERO_SUBTITLE}</PageSubtitle>
            )}
            {H1 === HEADING_TYPES.HERO_HEADING ? (
              <TenXGradientH1>{HERO_HEADING}</TenXGradientH1>
            ) : (
              <TenXGradient>{HERO_HEADING}</TenXGradient>
            )}
            <Paragraph>{HERO_PARAGRAPH}</Paragraph>
            <GetStartedButton noForm={noForm} />
          </FlexContent>
          <FlexContent maxWidth={740} flexNum={6}>
            <Image
              alt={`${HERO_TITLE} HERO_IMAGE`}
              src={`${DRUPAL_ENDPOINT}${HERO_IMAGE}`}
            />
          </FlexContent>
        </ContentContainer>
        <ContentContainer>
          <FlexContent flexNum={1}>
            <Image
              hideMobile
              alt=""
              src={`${DRUPAL_ENDPOINT}${HERO_CUSTOMER_STATS}`}
            />
          </FlexContent>
        </ContentContainer>
      </Section>
      {/* SECTION 2 */}
      <Section dark>
        <ContentContainer>
          <FlexContent maxWidth={500} paddingRight={80} flexNum={1}>
            {H1 === HEADING_TYPES.SECTION_2_TITLE ? (
              <SectionTitleH1 darkBg>{SECTION_2_TITLE}</SectionTitleH1>
            ) : (
              <SectionTitle darkBg>{SECTION_2_TITLE}</SectionTitle>
            )}
            <Paragraph darkBg>{SECTION_2_PARAGRAPH}</Paragraph>
            <GetStartedButton colorRed noForm={noForm} />
          </FlexContent>
          <FlexContent maxWidth={740} flexNum={1}>
            <Image
              alt={`${HERO_TITLE} EXPERTS`}
              src={`${DRUPAL_ENDPOINT}${SECTION_2_IMAGE}`}
            />
          </FlexContent>
        </ContentContainer>

        <ContentContainer>
          <FlexContent flexNum={1}>
            {SECTION_2_CUSTOMER_LOGOS_1 ? (
              <Image
                hideTablet
                src={`${DRUPAL_ENDPOINT}${SECTION_2_CUSTOMER_LOGOS_1}`}
              />
            ) : null}
            {SECTION_2_CUSTOMER_LOGOS_2 ? (
              <Image
                hideTablet
                src={`${DRUPAL_ENDPOINT}${SECTION_2_CUSTOMER_LOGOS_2}`}
              />
            ) : null}
            {SECTION_2_CUSTOMER_LOGOS_DESKTOP ? (
              <Image
                hideMobile
                src={`${DRUPAL_ENDPOINT}${SECTION_2_CUSTOMER_LOGOS_DESKTOP}`}
              />
            ) : null}
          </FlexContent>
        </ContentContainer>
        <Divider />

        <ContentContainer>
          <FlexContent paddingRight={40} flexNum={1}>
            <SectionTitle darkBg>HOW OUR AUCTIONS WORK</SectionTitle>
          </FlexContent>
          <FlexContent flexNum={2}>
            <Image
              hideTablet
              src={`${DRUPAL_ENDPOINT}${SECTION_2_STRATEGY_IMAGE_MOBILE}`}
            />
            <Image
              hideMobile
              src={`${DRUPAL_ENDPOINT}${SECTION_2_STRATEGY_IMAGE}`}
            />
          </FlexContent>
        </ContentContainer>
      </Section>
      {/* SECTION 3 */}
      <Section>
        <ContentContainer>
          <FlexContent maxWidth={500} paddingRight={40} flexNum={1}>
            <SectionTitle>{SECTION_3_TITLE}</SectionTitle>
            <Paragraph>{SECTION_3_PARAGRAPH}</Paragraph>
            <GetStartedButton colorRed noForm={noForm} />
          </FlexContent>
          <FlexContent maxWidth={740} flexNum={2}>
            <Image src={`${DRUPAL_ENDPOINT}${SECTION_3_IMAGE}`} />
          </FlexContent>
        </ContentContainer>
        {SECTION_3_ICON_SECTION && (
          <ContentContainer>
            {SECTION_3_ICON_SECTION.map((item) => (
              <IconContainer key={item.ICON_TITLE} flexNum={1}>
                <IconImage src={`${DRUPAL_ENDPOINT}${item.ICON_SRC}`} />
                <IconTitle>{item.ICON_TITLE}</IconTitle>
                <IconDescription>{item.ICON_DESCRIPTION}</IconDescription>
              </IconContainer>
            ))}
          </ContentContainer>
        )}
      </Section>
      {/* SECTION 4 */}
      <Section dark>
        <ContentContainer>
          <FlexContent maxWidth={600} flexNum={1}>
            <PageSubtitle footer darkBg>
              {SECTION_4_TITLE}
            </PageSubtitle>
            <CompanyLogos
              alt="Costar Logos"
              src={`${DRUPAL_ENDPOINT}/uploads/COSTAR_LOGOS_WHITE.png`}
            />
            {noForm && (
              <GetStartedButton colorRed viewProperties noForm={noForm} />
            )}
          </FlexContent>
          <FlexContent flexNum={1}>
            <Image
              maxWidth={740}
              src={`${DRUPAL_ENDPOINT}${SECTION_4_IMAGE}`}
            />
          </FlexContent>
          {!noForm && (
            <FlexContent id={pardotId} textAlign="center" flexNum={1}>
              <Paragraph center darkBg>
                {PARDOT_DESCRIPTION}
              </Paragraph>
              <PardotForm />
            </FlexContent>
          )}
        </ContentContainer>
      </Section>
    </Wrapper>
  )
}

const GetStartedButton = ({
  colorRed,
  noForm = false,
  viewProperties = false,
}) => {
  const onLinkClick = (e) => {
    e.preventDefault()
    document
      .getElementById(pardotId)
      .scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  return (
    <ButtonContainer>
      <GetStarted
        colorRed={colorRed}
        {...(!noForm
          ? { onClick: onLinkClick }
          : { href: viewProperties ? '/search' : '/signup' })}
      >
        {viewProperties ? 'VIEW PROPERTIES' : 'GET STARTED'}
      </GetStarted>
    </ButtonContainer>
  )
}

const Wrapper = styled.div`
  text-align: left;
  width: 100%;
`
const Section = styled.div`
  background-color: ${(props) => (props.dark ? '#000000' : 'transparent')};
  background-image: ${(props) =>
    props.bgImage ? `url(${props.image})` : 'unset'};
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${(props) => (props.topSection ? '140px 18px 70px' : '40px 18px')};
`

const FlexContent = styled.div`
  flex: ${(props) => props.flexNum || 0};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'unset')};
  text-align: ${(props) => props.textAlign || 'left'};
  scroll-margin-top: 200px;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding: 0 20px;
  }
`
const PageSubtitleH1 = styled.h1`
  color: ${(props) => (props.darkBg ? '#FFFFFF' : '#000000')};
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25em;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: ${(props) => (props.footer ? '36' : '46')}px;
    margin-bottom: 0;
  }
`

const PageSubtitle = styled.h3`
  color: ${(props) => (props.darkBg ? '#FFFFFF' : '#000000')};
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25em;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: ${(props) => (props.footer ? '36' : '46')}px;
    margin-bottom: 0;
  }
`
const SectionTitleH1 = styled(PageSubtitleH1)`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 27px;
    margin-bottom: 20px;
  }
`
const SectionTitle = styled(PageSubtitle)`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 27px;
    margin-bottom: 20px;
  }
`
const CompanyLogos = styled.img`
  margin: 0 auto;
  max-width: 430px;
  padding: 20px 0;
  width: 100%;
`
const TenXGradientH1 = styled.h1`
  background: linear-gradient(
    90deg,
    rgba(0, 84, 166, 1) 0%,
    rgba(206, 32, 40, 1) 75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 20px;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 22px;
  }
`

const TenXGradient = styled.p`
  background: linear-gradient(
    90deg,
    rgba(0, 84, 166, 1) 0%,
    rgba(206, 32, 40, 1) 75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 20px;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 22px;
  }
`

const Paragraph = styled.p`
  color: ${(props) => (props.darkBg ? '#FFFFFF' : '#5e5e5e')};
  font-size: 14px;
  line-height: 1.5em;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 15px;
    margin: ${(props) => (props.center ? '0 auto' : 'unset')};
  }
`
const ButtonContainer = styled.div`
  margin: 40px auto;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    text-align: left;
  }
`
const GetStarted = styled.a`
  color: #ffffff;
  background: ${(props) => (props.colorRed ? '#ce2027' : '#0655a5')};
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 30px;
  transition: all 0.3s;

  :hover {
    color: ${(props) => (props.colorRed ? '#ce2027' : '#0655a5')};
    border: 1px solid ${(props) => (props.colorRed ? '#ce2027' : '#0655a5')};
    background-color: #ffffff;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 16px;
  }
`
const Image = styled.img`
  display: ${(props) => (props.hideMobile ? 'none' : 'block')};
  margin: 20px auto;
  max-width: 1440px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: ${(props) => (props.hideTablet ? 'none' : 'block')};
  }
`

const Divider = styled.hr`
  margin: 20px auto;
  max-width: 1440px;
`
const IconContainer = styled(FlexContent)`
  padding: 15px 0;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    min-height: 250px;
    padding: 0;
    padding-right: 15px;
    text-align: left;
  }
`
const IconImage = styled.img`
  max-width: 102px;
`
const IconTitle = styled.h4`
  font-weight: 700;
`
const IconDescription = styled.p`
  font-size: 15px;
`

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  ${IconContainer} + ${IconContainer} {
    border-top: 1px solid #dddddd;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    align-items: center;
    display: flex;
    justify-content: space-between;

    ${IconContainer} + ${IconContainer} {
      border: none;
    }
  }
`

LandingPage.propTypes = {
  PAGE_CONSTANTS: PropTypes.shape(LandingPageTypes),
  noForm: PropTypes.bool,
  H1: PropTypes.any,
  pageType: PropTypes.string,
}

GetStartedButton.propTypes = {
  colorRed: PropTypes.bool,
  noForm: PropTypes.bool,
  viewProperties: PropTypes.bool,
}

export default LandingPage
