/* eslint-disable react/prop-types */
import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import configment from '@ten-x/configment'
import sanitizeHtml from 'sanitize-html'
import slugify from 'slugify'

import { truncateSummary } from '../../utils/format'

const FeaturedArticle = ({ post, categories }) => {
  let category

  const articleCategoryId =
    post?.field_article_category?.data?.id || post?.field_article_category?.id

  const matchingCategory = categories?.find(
    ({ id }) => id === articleCategoryId
  )
  if (matchingCategory) {
    category = matchingCategory?.attributes?.field_slug
  }

  const title = post?.title
  let slug = post?.field_article_slug
  if (!slug) {
    slug = `${slugify(post?.title, { lower: true })}/${post?.id}`
  }
  const customAuthor = post?.field_article_custom_author

  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')
  const DRUPAL_ENDPOINT = configment.get('DRUPAL_BASE_URL')
  const featuredImage =
    post?.field_article_featured_image?.field_media_image?.uri?.url
  const imageSrc = `${DRUPAL_ENDPOINT}${featuredImage}`
  const pureContent = truncateSummary(
    sanitizeHtml(post?.field_article_body?.processed)
  )
  const articleUrl = `${category}/${slug}`

  return (
    <>
      <TitleContainer>
        <h2>FEATURED ARTICLE</h2>
        <Divider />
      </TitleContainer>

      <FeaturedContainer>
        <FeaturedImage imageSrc={imageSrc} />
        <a href={`${KNOWLEDGE_CENTER_URL}/${articleUrl}`}>
          <h3>{title || ''}</h3>
        </a>
        <ContentContainer
          dangerouslySetInnerHTML={{
            __html: pureContent,
          }}
        />
        <ContentContainer>By {customAuthor || 'Ten-X'}</ContentContainer>
      </FeaturedContainer>
    </>
  )
}

const FeaturedContainer = styled.div`
  padding: 20px 0;
  text-align: left;
  font-weight: 300;
  h2,
  h3 {
    color: rgba(89, 89, 89, 1);
    font-size: 20px;
    font-weight: 300;
    margin-top: 10px;

    :hover {
      color: #1890ff;
    }

    @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      font-size: 24px;
    }
    @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      font-size: 30px;
    }
  }
`

const FeaturedImage = styled.div`
  background-image: url('${(props) => props.imageSrc}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  min-width: 35%;
  width: 100%;
`

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  h2,
  h3 {
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 15px;
  }
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    h2,
    h3 {
      font-size: 20px;
    }
  }
`

const ContentContainer = styled.div`
  color: rgba(136, 136, 136, 1);
  font-size: 16px;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const Divider = styled.hr`
  border-bottom: 1px solid black;
  flex-grow: 1;
`

FeaturedArticle.propTypes = {
  post: PropTypes.shape({
    acf: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        rendered: PropTypes.string,
      }),
    ]),
    content: PropTypes.shape({
      rendered: PropTypes.string,
    }),
    excerpt: PropTypes.shape({
      rendered: PropTypes.string,
    }),
    sticky: PropTypes.bool,
  }),
}

export default FeaturedArticle
