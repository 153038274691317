/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import ResizeObserver from 'rc-resize-observer'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Spin, Empty, Menu } from 'antd'
import sanitizeHtml from 'sanitize-html'
import styled from '@emotion/styled'
import { styles } from '@ten-x/fe-lib-ui2020'
import { useParams } from 'react-router-dom'
import logger from '@ten-x/logger'

import { truncateSummary } from '../../utils/format'
import WPProvider from '../../data_providers/drupal'
import LEGAL_DOCS from '../../constants/legal_docs'

const { withThemeProvider } = styles
export const LegalPage = ({ isArchives, slugId }) => {
  const [loading, setLoading] = useState(true)
  const [legalDoc, setLegalDoc] = useState()
  const [legalDocSlug, setLegalDocSlug] = useState()
  const [showLegalMenu, setShowLegalMenu] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState([])
  const [menuMode, setMenuMode] = useState('horizontal')

  const { pathSlug, slug } = useParams()

  useEffect(() => {
    const name = slug || slugId || pathSlug
    const slugName = isArchives ? `${name}-archives` : name
    const showMenu =
      slugName === LEGAL_DOCS.TERMS_SLUG ||
      slugName === LEGAL_DOCS.PARTICIPATION_TERMS_SLUG ||
      slugName === LEGAL_DOCS.LICENSING_SLUG

    if (showMenu) {
      setShowLegalMenu(showMenu)
      setSelectedMenuItem([slugName])
    }

    setLegalDocSlug(slugName)
  }, [])

  useEffect(() => {
    if (legalDocSlug) {
      setLoading(true)
      const getLegalDocument = async (slug) => {
        logger.info('slug', slug)

        if (showLegalMenu) {
          setSelectedMenuItem([slug])
        }

        const response = await WPProvider.getLegalDocument(slug)
        setLegalDoc(response)
        setLoading(false)
      }

      getLegalDocument(legalDocSlug)
    }
  }, [legalDocSlug])

  const title = legalDoc?.attributes?.title?.split('--')[0] || ''
  const redirectLink = legalDoc?.attributes?.field_redirect_link || ''
  const pureContent = sanitizeHtml(
    legalDoc?.attributes?.field_article_body?.processed,
    {
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        p: ['style'],
      },
    }
  )

  if (redirectLink) {
    window.location.href = redirectLink.uri
  }

  const description = pureContent
    ? truncateSummary(
        sanitizeHtml(pureContent || '', {
          allowedTags: [],
        })
      )
        .replace(/<[^>]*>?/gm, '')
        .replaceAll('\n', ' ')
    : ''

  let defaultTitle = ''
  if (slug) {
    defaultTitle = slug.replaceAll('-', ' ').split(' ')
    for (let i = 0; i < defaultTitle.length; i++) {
      defaultTitle[i] =
        defaultTitle[i][0].toUpperCase() + defaultTitle[i].substr(1)
    }
    defaultTitle.join(' ')
  }

  const handleClick = (e) => {
    setLegalDocSlug(e.key)
    window.history.replaceState(e.key, e.title, `/company/legal/${e.key}`)
  }

  const handleResize = ({ width }) => {
    if (width > 650) {
      setMenuMode('horizontal')
    } else {
      setMenuMode('vertical')
    }
  }

  return (
    <ResizeObserver onResize={handleResize}>
      {showLegalMenu ? (
        <MenuContainer>
          <MenuContent
            onClick={handleClick}
            mode={menuMode}
            selectedKeys={selectedMenuItem}
            style={{ fontSize: 16 }}
          >
            <Menu.Item
              key={LEGAL_DOCS.TERMS_SLUG}
              title={LEGAL_DOCS.TERMS_TITLE}
            >
              {LEGAL_DOCS.TERMS_TITLE}
            </Menu.Item>
            <Menu.Item
              key={LEGAL_DOCS.PARTICIPATION_TERMS_SLUG}
              title={LEGAL_DOCS.PARTICIPATION_TERMS_TITLE}
            >
              {LEGAL_DOCS.PARTICIPATION_TERMS_TITLE}
            </Menu.Item>
            <Menu.Item
              key={LEGAL_DOCS.LICENSING_SLUG}
              title={LEGAL_DOCS.LICENSING_TITLE}
            >
              {LEGAL_DOCS.LICENSING_TITLE}
            </Menu.Item>
          </MenuContent>
        </MenuContainer>
      ) : null}
      <ArticleContainer menu={showLegalMenu}>
        <Helmet>
          <title>{title || defaultTitle}</title>
          <meta name="description" content={description} />
          <link
            rel="canonical"
            href={`https://www.ten-x.com/company/legal/${slug}`}
          />
        </Helmet>
        {loading || redirectLink ? (
          <Spin />
        ) : legalDoc ? (
          <ArticleContentContainer>
            <TitleContainer
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(title),
              }}
            />
            <ArticleContent
              dangerouslySetInnerHTML={{
                __html: pureContent,
              }}
            />
          </ArticleContentContainer>
        ) : (
          <Empty description="Document Not Found" />
        )}
      </ArticleContainer>
    </ResizeObserver>
  )
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 0 20px;
  // max-width: 1440px;
  width: 100%;
  background-color: #e6eff4;
  position: absolute;
  top: 0;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: row;
  }
`

const MenuContent = styled(Menu)`
  background-color: #e6eff4;
`

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  font-size: 16px;
  padding: 0 20px;
  max-width: 1440px;
  padding-top: ${(props) => (props.menu ? '100px' : '20px')};

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    flex-direction: row;
    margin: 40px 0;
    padding-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    padding-top: 20px;
  }
`

const ArticleContentContainer = styled.div`
  color: rgba(136, 136, 136, 1);
  font-weight: 300;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const TitleContainer = styled.h1`
  color: rgba(89, 89, 89, 1);
  font-size: 30px;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
  }
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 50px;
  }
`
const ArticleContent = styled.div``

LegalPage.propTypes = {
  isArchives: PropTypes.bool,
  slugId: PropTypes.string,
}

export default withThemeProvider(LegalPage)
