import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, InputNumber, Tabs, message } from 'antd'

const MSG_KEY = 'socketio'

const { TabPane } = Tabs

const SubscriptionCard = ({
  name,
  subscription,
  createSubscription,
  groupId,
  destroySubscription,
}) => {
  const [newListingId, setNewListingId] = useState(20017142)
  const [listingIds, setListingIds] = useState([])
  const [listingUpdateEvents, setListingUpdateEvents] = useState([])
  const [bidderRegistrationUpdateEvents, setBidderRegistrationUpdateEvents] =
    useState([])
  const [bidderPofUpdateEvents, setBidderPofUpdateEvents] = useState([])
  const [onSellerRoomUpdateEvents, setOnSellerRoomUpdateEvents] = useState([])
  const [onBuyNowUpdateEvents, setOnBuyNowRoomUpdateEvents] = useState([])
  const [connectTime, setConnectTime] = useState(null)
  const [disconnectTime, setDisconnectTime] = useState(null)

  const onListingUpdate = (listingId, event) => {
    setListingUpdateEvents((eventList) => [
      ...eventList,
      { listingId, event: formatEvent(event) },
    ])
  }

  const onBidderRegUpdate = (listingId, event) => {
    setBidderRegistrationUpdateEvents((eventList) => [
      ...eventList,
      { listingId, event: formatEvent(event) },
    ])
  }

  const onBidderPofUpdate = (event) => {
    setBidderPofUpdateEvents((eventList) => [
      ...eventList,
      { event: formatEvent(event) },
    ])
  }

  const onSellerRoomUpdate = (listingId, event) => {
    setOnSellerRoomUpdateEvents((eventList) => [
      ...eventList,
      { listingId, event: formatEvent(event) },
    ])
  }

  const onBuyNowUpdate = (listingId, event) => {
    setOnBuyNowRoomUpdateEvents((eventList) => [
      ...eventList,
      { listingId, event: formatEvent(event) },
    ])
  }

  const onConnectionUpdate = (connectTime, disconnectTime) => {
    setConnectTime(connectTime)
    setDisconnectTime(disconnectTime)

    if (connectTime && disconnectTime) {
      if (connectTime > disconnectTime) {
        message.success({
          content: 'Real time updates reconnected successfully',
          key: MSG_KEY,
          duration: 3,
        })
      } else if (disconnectTime > connectTime) {
        message.warn({
          content: 'Real time updates have been disconnected, reconnecting...',
          key: MSG_KEY,
          duration: 0,
        })
      }
    }
  }

  const formatEvent = (event) => JSON.stringify(event, undefined, 4)

  const addListing = () => {
    let targetListings
    if (!subscription) {
      targetListings = [newListingId]
      createSubscription(groupId, {
        listingIds: targetListings,
        onListingUpdate,
        onBidderRegUpdate,
        onBidderPofUpdate,
        onSellerRoomUpdate,
        onBuyNowUpdate,
        onConnectionUpdate,
      })
      setListingIds(targetListings)
    } else {
      targetListings = [...subscription.listingIds, newListingId]
      subscription.setListingIds(targetListings)
      setListingIds(Array.from(subscription.listingIds))
    }
  }

  const deleteSubscription = () => {
    destroySubscription(groupId)
  }

  const removeSubscription = (id) => {
    listingIds.splice(listingIds.indexOf(id), 1)
    subscription.setListingIds(listingIds)
    setListingIds(Array.from(subscription.listingIds))
  }

  return (
    <Card
      title={`Subscription group ${groupId + 1}`}
      extra={
        <Button onClick={deleteSubscription}>Delete subscription group</Button>
      }
    >
      <div>
        {' '}
        Subscribe to listing:{' '}
        <InputNumber
          value={newListingId}
          onChange={(value) => setNewListingId(value)}
        />{' '}
        <Button onClick={addListing}>Subscribe</Button>
      </div>
      {subscription && (
        <>
          <h2>Selected Listings</h2>
          {listingIds.map((listingId, index) => (
            <div key={index}>
              {listingId}{' '}
              <Button onClick={() => removeSubscription(listingId)}>
                Remove Listing subscription
              </Button>
            </div>
          ))}
          <Tabs>
            <TabPane tab="Listing Updates" key="1">
              {listingUpdateEvents.map((listingEvent, index) => (
                <div key={index}>
                  <p>New Event - Listing Id: {listingEvent.listingId}</p>
                  <pre>{listingEvent.event}</pre>
                </div>
              ))}
            </TabPane>
            <TabPane tab="Bidder registration Updates" key="2">
              {bidderRegistrationUpdateEvents.map((bidRegEvent, index) => (
                <div key={index}>
                  <p>New Event - Listing Id: {bidRegEvent.listingId}</p>
                  <pre>{bidRegEvent.event}</pre>
                </div>
              ))}
            </TabPane>
            <TabPane tab="Bidder pof Updates" key="3">
              {bidderPofUpdateEvents.map((bidPofEvent, index) => (
                <div key={index}>
                  <pre>{bidPofEvent.event}</pre>
                </div>
              ))}
            </TabPane>
            <TabPane tab="Seller Room Updates" key="4">
              {onSellerRoomUpdateEvents.map((sellerRoomEvent, index) => (
                <div key={index}>
                  <p>New Event - Listing Id: {sellerRoomEvent.listingId}</p>
                  <pre>{sellerRoomEvent.event}</pre>
                </div>
              ))}
            </TabPane>
            <TabPane tab="Buy Now Updates" key="5">
              {onBuyNowUpdateEvents.map((buyNowRoomEvent, index) => (
                <div key={index}>
                  <p>New Event - Listing Id: {buyNowRoomEvent.listingId}</p>
                  <pre>{buyNowRoomEvent.event}</pre>
                </div>
              ))}
            </TabPane>
            <TabPane tab="Connection Updates" key="6">
              {connectTime ? (
                <div>
                  connectTime: {connectTime} (
                  {new Date(connectTime).toLocaleString()})
                </div>
              ) : null}
              {disconnectTime ? (
                <div>
                  disconnectTime: {disconnectTime} (
                  {new Date(disconnectTime).toLocaleString()})
                </div>
              ) : null}
            </TabPane>
          </Tabs>
        </>
      )}
    </Card>
  )
}

SubscriptionCard.propTypes = {
  createSubscription: PropTypes.func,
  name: PropTypes.string,
  subscription: PropTypes.object,
  groupId: PropTypes.number,
  destroySubscription: PropTypes.func,
}

export default SubscriptionCard
