import React from 'react'
import styled from '@emotion/styled'
import { styles } from '@ten-x/fe-lib-ui2020'
import configment from '@ten-x/configment'

import { AdvertisementBox, Banner } from '../sections'
import analytics from '../../services/analytics'
import { BANNERS } from '../../constants/home'

import KnowledgeCenter from './knowledgeCenter'

const { withThemeProvider } = styles

export const Home = (props) => {
  if (typeof window !== 'undefined' && window.location.pathname !== '/') {
    window.history.replaceState({}, '', window.location.origin)
    document.title = window.location.origin
  }

  const handleLearnMoreClick = () => {
    window.location.href = '/sell-commercial-real-estate'
    analytics.onLearnMore()
  }

  const handleButtonClick = () => {
    analytics.onViewAllTenXListingsOnLoopNet()
    const LOOPNET_HOME_URL = configment.get('LOOPNET_HOME_URL')
    window.location.href = `${LOOPNET_HOME_URL}/search/commercial-real-estate/usa/auctions/`
  }

  return (
    <HomeContainer>
      <BannerContainer>
        <Header>TEN-X AUCTIONS NOW ON LOOPNET</Header>
        <ButtonLarge onClick={handleButtonClick}>
          View All Ten-X Listings on LoopNet
        </ButtonLarge>

        <Description>
          Ten-X auctions are now on LoopNet, the world's largest commercial real
          estate marketplace. We're simplifying the way investors underwrite,
          bid, and buy.
        </Description>
      </BannerContainer>
      <Banner
        header={BANNERS.POWER_ONLINE.TITLE}
        description={BANNERS.POWER_ONLINE.DESCRIPTION}
        descSize={BANNERS.POWER_ONLINE.SIZE}
      />
      <AdvertisementBox />
      <Banner
        colorBg="#F4F4F3"
        header={BANNERS.ALL_INVESTORS.TITLE}
        images={BANNERS.ALL_INVESTORS.IMAGES}
        descSize={BANNERS.ALL_INVESTORS.SIZE}
      />
      <Button onClick={handleLearnMoreClick}>Learn More</Button>
      <Banner
        header={BANNERS.HELP_BROKERS.TITLE}
        description={BANNERS.HELP_BROKERS.DESCRIPTION}
        descSize={BANNERS.HELP_BROKERS.SIZE}
      />
      <LapTopWrapper>
        <LapTopImage src="/images/homepage/laptopImage.png" />
      </LapTopWrapper>
      <Banner
        colorBg="#F4F4F3"
        header={BANNERS.CRE_AUCTION.TITLE}
        description={BANNERS.CRE_AUCTION.DESCRIPTION}
        descSize={BANNERS.CRE_AUCTION.SIZE}
      />
      <KnowledgeCenter />
    </HomeContainer>
  )
}

export default withThemeProvider(Home)

export const HomeContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(0)}, auto;
  text-align: center;
  width: 100%;
`

const BannerContainer = styled.div`
  text-align: center;
  padding: 20px;
  margin-bottom: 80px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    margin-bottom: 15px;
  }
`

const Header = styled.div`
  font-size: 4rem;
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 2rem;
  }
`

const Button = styled.button`
  background-color: #d0021b;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 50px 0;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  width: ${({ width }) => width || '200px'};
`

const ButtonLarge = styled.button`
  background-color: #d0021b;
  color: white;
  border: none;
  padding: 20px 10px;
  margin: 50px 0;
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 8px;
  width: 500px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding: 10px 20px;
    margin: 50px 0;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 8px;
    width: 90%;
  }
`

const LapTopWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const LapTopImage = styled.img`
  height: auto;
  width: 60%;
  min-width: 250px;
`

const Description = styled.div`
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;
  color: #333;
  max-width: 50%;
  font-weight: 200;
  font-size: 1.5rem;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    padding: 0;
    max-width: 90%;
    &:before {
      content: none;
    }
    font-size: 1.2rem;
  }
`
