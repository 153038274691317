export default {
  TITLE: 'Find Commercial Real Estate Properties for Sale',
  DESCRIPTION:
    'Maximize your commercial real estate investment opportunities with our transparent auctions. Explore properties nationwide and bid online with confidence. Explore active listings for properties including office, retail, hotel, multifamily, industrial, and more.',
  CANONICAL: 'https://www.ten-x.com/buy-commercial-real-estate',
  HERO_TITLE: 'BUYERS',
  HERO_SUBTITLE: 'FIND MORE DEALS & ACCELERATE YOUR TRANSACTION.',
  HERO_HEADING: 'THE #1 DIGITAL CRE AUCTION PLATFORM',
  HERO_PARAGRAPH:
    'Maximize your investment opportunities with Ten-X. We democratize the buying process by giving you transparency, control over bidding, transaction management, and better access to listings nationwide.',
  HERO_IMAGE: '/uploads/BUYER_HERO_V2.png',
  HERO_CUSTOMER_STATS: '/uploads/BUYER_STATS_V3.png',
  SECTION_2_TITLE: 'FIND UNTAPPED OPPORTUNITIES',
  SECTION_2_PARAGRAPH:
    'Ten-X matches up investors to properties that meet their needs. Evaluate your options with ease and access a more complete due diligence package, right from the property details page. Go from browsing to bidding with confidence.',
  SECTION_2_IMAGE: '/uploads/BUYER_SECTION_2_V2.png',
  SECTION_2_STRATEGY_IMAGE_MOBILE: '/uploads/BUYER_STRATEGY_MOBILE_V2.png',
  SECTION_2_STRATEGY_IMAGE: '/uploads/BUYER_STRATEGY_V2.png',
  SECTION_3_TITLE: 'BID WITH CONFIDENCE',
  SECTION_3_PARAGRAPH:
    'Ten-X auctions ensure transparency and competition, providing buyers worldwide with confidence in fair market prices.',
  SECTION_3_IMAGE: '/uploads/BUYER_CARDS.png',

  SECTION_4_TITLE: 'EXPLORE ACTIVE PROPERTIES & UPCOMING AUCTIONS.',
  SECTION_4_PARAGRAPH:
    "Browse our active listings and find your next investment opportunity. Bid with confidence and speed on Ten-X, where the market's best properties are always just a click away.",
  SECTION_4_IMAGE: '/uploads/BUYER_CUSTOMER_V2.png',
}
