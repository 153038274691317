import React, { Component } from 'react'
import Helmet from 'react-helmet'
import config from '@ten-x/configment'
import _get from 'lodash/get'

import SharedMessage from './containers/sharedMessage'
import types from './constants/prop_types'
import MSG from './constants/messages'

// Main ant design css entry point
import 'antd/dist/antd.css'
// required for phone input libraries
import 'libphonenumber-js'
import 'react-phone-input-2/lib/style.css'

class App extends Component {
  static propTypes = {
    children: types.children.isRequired,
  }

  render() {
    const homeUrl = config.get('TENX_HOME_URL', 'https://www.ten-x.com')
    const titleTemplate = '%s'
    const meta = [{ name: 'description', content: MSG.HELMET_META_DESCRIPTION }]

    if (typeof window !== 'undefined') {
      meta.push({
        name: 'apple-itunes-app',
        content: `app-id=${_get(
          config.get('appstore'),
          'cre.APPLE_ITUNES_ID'
        )}, affiliate-data=ct=SmartAppBanner&pt=315353, app-argument=${
          window.location.href
        }`,
      })
    }

    return (
      <>
        {/* These head tags are shared for the whole application and can be overriden by individual pages. */}
        <Helmet titleTemplate={titleTemplate} meta={meta}>
          <link rel="canonical" href="https://www.ten-x.com" />
          {/* <!-- generics --> */}
          <link rel="icon" href={`${homeUrl}/favicon.ico`} sizes="48x48" />
          <link rel="icon" href={`${homeUrl}/favicon-16.png`} sizes="16x16" />
          <link rel="icon" href={`${homeUrl}/favicon-32.png`} sizes="32x32" />
          <link
            rel="icon"
            href={`${homeUrl}/favicon-180.png`}
            sizes="180x180"
          />

          {/* <!-- Android --> */}
          <link
            rel="icon"
            href={`${homeUrl}/android-chrome-192x192.png`}
            sizes="192x192"
          />
          <link
            rel="icon"
            href={`${homeUrl}/android-chrome-512x512.png`}
            sizes="512x512"
          />

          {/* <!-- iOS --> */}
          <link
            rel="apple-touch-icon"
            href={`${homeUrl}/apple-touch-icon.png`}
            sizes="180x180"
          />

          {/* <!— Windows 8.1 + IE11 and above —> */}
          <meta
            name="msapplication-config"
            content={`${homeUrl}/browserconfig.xml`}
          />
        </Helmet>
        <SharedMessage />
        {this.props.children}
      </>
    )
  }
}

export default App
