import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import configment from '@ten-x/configment'
import sanitizeHtml from 'sanitize-html'

const HelpContent = ({ content }) => {
  const DRUPAL_ENDPOINT = configment.get('DRUPAL_BASE_URL')
  const articleImage = content?.image?.attributes?.uri?.url
  const imageSrc = `${DRUPAL_ENDPOINT}${articleImage}`
  const title = content?.attributes?.title || ''
  const pureContent = sanitizeHtml(content?.attributes?.body?.processed) || ''

  return (
    <ContentArticleContainer>
      <ArticleContentContainer>
        <ArticleTitle
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(title.split('--')[0]),
          }}
        />
        <ArticleContent
          dangerouslySetInnerHTML={{
            __html: pureContent,
          }}
        />
      </ArticleContentContainer>
      {articleImage ? <ArticleImage imageSrc={imageSrc} /> : null}
    </ContentArticleContainer>
  )
}

const ContentArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  font-size: 16px;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: row;
    margin: 0;
  }
`

const ArticleImage = styled.div`
  align-items: center;
  background-image: url('${(props) => props.imageSrc}');
  background-position: center top;
  background-repeat: no-repeat;
  // background-size: cover;
  display: flex;
  height: 300px;
  min-width: 35%;
  width: 100%;
  h3 {
    color: white;
    font-weight: 400;
    font-size: 22px;
    margin-left: 20px;
    margin-bottom: 0;

    @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      font-size: 40px;
      margin-left: 60px;
    }

    @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      font-size: 60px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    min-height: ${(props) => (props.helpCenter ? '300px' : 'unset')};
    height: 240px;
    max-width: ${(props) => (props.helpCenter ? 'unset' : '30%')};
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    height: 300px;
  }
`

const ArticleContentContainer = styled.div`
  color: rgba(136, 136, 136, 1);
  font-weight: 300;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    align-self: start;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const ArticleTitle = styled.h4`
  color: #595959;
  font-size: 20px;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 16px;
    line-height: 16px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
    line-height: 20px;
  }
`

const ArticleContent = styled.div``

const MemberObject = {
  title: PropTypes.string,
}

HelpContent.propTypes = {
  content: PropTypes.shape(MemberObject),
}

export default HelpContent
