/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Spin, Empty } from 'antd'
import configment from '@ten-x/configment'
import styled from '@emotion/styled'
import logger from '@ten-x/logger'

import WPProvider from '../../data_providers/drupal'

export const Files = () => {
  const DRUPAL_ENDPOINT = configment.get('DRUPAL_BASE_URL')
  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState()

  const { year, month, filename } = useParams()

  let path = ''
  if (year) path += `/${year}`
  if (month) path += `/${month}`
  if (filename) path += `/${filename}`

  if (path) {
    logger.info('path', path)
  }

  useEffect(() => {
    setLoading(true)
    const getFile = async (filename) => {
      const response = await WPProvider.getFile(filename)
      setFile(response)
      setLoading(false)
    }

    getFile(filename)
  }, [filename])

  if (file) {
    window.location.href = `${DRUPAL_ENDPOINT}${file?.attributes?.uri?.url}`
  }

  return (
    <ArticleContainer>
      <>{loading || file ? <Spin /> : <Empty description="Not Found" />}</>
    </ArticleContainer>
  )
}

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  font-size: 16px;
  padding: 0 20px;
  max-width: 1440px;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: row;
    margin: 40px 0;
  }
`

export default Files
