import React from 'react'
import styled from '@emotion/styled'

import { Banner, Statistics, VideoPlayer } from '../sections'
import { BANNERS, STATISTICS } from '../../constants/learn_more'

import MilestoneChart from './mileStoneChart'

const LearnMore: React.FC = () => {
  return (
    <Container>
      <Banner
        header={BANNERS.TRANSACTION_TECH.TITLE}
        description={BANNERS.TRANSACTION_TECH.DESCRIPTION}
        descSize={BANNERS.TRANSACTION_TECH.SIZE}
      />
      <LargeScreenOnly>
        <MilestoneChart />
      </LargeScreenOnly>
      <Statistics
        stats={STATISTICS.OWNERS.STATS}
        description={STATISTICS.OWNERS.DESCRIPTION}
      />
      <Banner
        colorBg="#F4F4F3"
        header={BANNERS.OWNERS.TITLE}
        images={BANNERS.OWNERS.IMAGES}
        descSize={BANNERS.TRANSACTION_TECH.SIZE}
      />
      <Statistics
        stats={STATISTICS.BROKERS.STATS}
        description={STATISTICS.BROKERS.DESCRIPTION}
      />
      <Banner
        colorBg="#F4F4F3"
        header={BANNERS.BROKERS.TITLE}
        images={BANNERS.BROKERS.IMAGES}
        descSize={BANNERS.TRANSACTION_TECH.SIZE}
      />
      <MainHeader>
        INDUSTRY LEADERS SPEAK OF THEIR TRANSACTION SUCCESS
      </MainHeader>
      <RedBorderLine />
      <VideoPlayer />
    </Container>
  )
}

const LargeScreenOnly = styled.div`
  @media (max-width: 1430px) {
    display: none;
  }
`

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 100%;
`

export const MainHeader = styled.div`
  padding: 80px 0px 25px;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.black};
  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 1.1rem;
  }
`

export const RedBorderLine = styled.div`
  width: 300px;
  height: 1px;
  background-color: #cf0000;
  margin: 20px auto;
  opacity: 1;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    margin: 10px auto;
  }
`

export default LearnMore
