export default {
  TERMS_TITLE: 'Website Terms of Use',
  TERMS_SLUG: 'terms',

  PARTICIPATION_TERMS_TITLE: 'Participation Terms',
  PARTICIPATION_TERMS_SLUG: 'participation-terms',

  LICENSING_TITLE: 'License & Disclosures',
  LICENSING_SLUG: 'licensing',
}
