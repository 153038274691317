import configment from '@ten-x/configment'
import logger from '@ten-x/logger'

import WPTypes from '../constants/wordpressTypes'

const MAX_RELATED_ARTICLES = 3
const MAX_ID_TAGS = 5
const SORT_PARAM = 'sort=-field_custom_publication_date'
const INCLUDE_IMAGE_PARAM =
  'include=field_article_featured_image.field_media_image'
const INCLUDE_HEADSHOT_PARAM = 'include=field_headshot.field_media_image'
const FILE_URL_PARAM = 'fields[file--file]=uri,url'
const JASON_INCLUDE_PARAM = 'jsonapi_include=1'
const MAX_RELATED_ARTICLE_PARAM = `page[limit]=${MAX_RELATED_ARTICLES}`

async function searchArticles(searchTerm, page = 1, perPage) {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const response = await fetch(
      `${DRUPAL_ENDPOINT}/articles?page[limit]=${perPage}&page[offset]=${
        (page - 1) * perPage
      }&filter[id][condition][path]=field_article_body.value&filter[id][condition][operator]=CONTAINS&filter[id][condition][value]=${searchTerm}&${SORT_PARAM}&${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}`
    )
    const { data, included, meta } = await response.json()
    const images = included?.filter((image) => image.type === 'file--file')

    const searchArticles = []
    for (let i = 0; i < data.length; i++) {
      const searchArticle = data[i]
      searchArticles.push({
        id: searchArticle.id,
        ...searchArticle.attributes,
        ...searchArticle.relationships,
        images: images[i]?.attributes?.uri,
      })
    }

    logger.info('searchArticles ', searchArticles)
    return {
      postCount: meta?.count,
      posts: searchArticles,
    }
  } catch (e) {
    return e
  }
}

// async function searchAutosuggestions(searchTerm) {
//   const WP_ENDPOINT = `${configment.get('WP_BASE_URL')}`
//   try {
//     const response = await fetch(`${WP_ENDPOINT}/search?search=${searchTerm}`)
//     return await response.json()
//   } catch (e) {
//     return e
//   }
// }

async function getArticleEducationVideos(excludeId) {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const response = await fetch(
      `${DRUPAL_ENDPOINT}/education_videos?${SORT_PARAM}&${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}`
    )
    const { data, included } = await response.json()
    const images = included?.filter((image) => image.type === 'file--file')

    const educationVideos = []
    for (let i = 0; i < data.length; i++) {
      const educationVideo = data[i]
      if (!excludeId || educationVideo.id !== excludeId) {
        educationVideos.push({
          id: educationVideo.id,
          ...educationVideo.attributes,
          ...educationVideo.relationships,
          images: images[i]?.attributes?.uri,
        })
      }
    }
    logger.info('educationVideos ', educationVideos)

    return educationVideos
  } catch (e) {
    return e
  }
}

async function getRecentArticles() {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const categories = await fetch(
      `${DRUPAL_ENDPOINT}/taxonomy_term/categories`
    )
    const categoryResponse = await categories.json()
    const categoryData = categoryResponse.data
    const categorySlugToId = {}
    const POST_LIMIT = 3

    if (categoryData?.length > 0) {
      categoryData.forEach((category) => {
        if (WPTypes[category.attributes.name]) {
          categorySlugToId[category.attributes.field_slug] = category.id
        }
      })
    }

    const featuredArticle = await fetch(
      `${DRUPAL_ENDPOINT}/articles?filter[field_article_sticky_post][value]=1&page[limit]=1&${SORT_PARAM}&${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}&${JASON_INCLUDE_PARAM}`
    )
    const featuredArticleResponse = await featuredArticle.json()
    let queryFilterFeaturedArticle = ''
    if (featuredArticleResponse?.data?.length > 0) {
      queryFilterFeaturedArticle = `filter[id][value]=${featuredArticleResponse.data[0].id}&filter[id][operator]=<>`
    }

    const CATEGORY_ENDPOINT = `${DRUPAL_ENDPOINT}/articles?${queryFilterFeaturedArticle}&page[limit]=${POST_LIMIT}&${SORT_PARAM}&${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}&${JASON_INCLUDE_PARAM}&filter[field_article_category.id][value]=`
    const [newsPosts, insightsPosts, educationPosts, successPosts] =
      await Promise.all([
        fetch(`${CATEGORY_ENDPOINT}${categorySlugToId.news}`),
        fetch(`${CATEGORY_ENDPOINT}${categorySlugToId.insights}`),
        fetch(`${CATEGORY_ENDPOINT}${categorySlugToId.education}`),
        fetch(`${CATEGORY_ENDPOINT}${categorySlugToId['success-stories']}`),
      ])
    const { data: featuredArticleData } = featuredArticleResponse
    const { data: newsPostsData } = await newsPosts.json()
    const { data: insightsPostsData } = await insightsPosts.json()
    const { data: educationPostsData } = await educationPosts.json()
    const { data: successPostsData } = await successPosts.json()

    const recentArticles = {
      featuredArticle: featuredArticleData,
      news: newsPostsData,
      insights: insightsPostsData,
      education: educationPostsData,
      successStories: successPostsData,
      categories: categoryData,
    }

    logger.info('recentArticles ', recentArticles)
    return recentArticles
  } catch (e) {
    return e
  }
}

async function getArticle(id, slug) {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const articles = []
    const articlesResp = await fetch(
      `${DRUPAL_ENDPOINT}/articles?filter[field_article_slug]=${slug}&${SORT_PARAM}&${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}`
    )
    const { data: articlesData, included: articlesIncluded } =
      await articlesResp.json()

    if (articlesData.length) {
      const articleImages = articlesIncluded?.filter(
        (image) => image.type === 'file--file'
      )
      for (let i = 0; i < articlesData.length; i++) {
        const article = articlesData[i]
        articles.push({
          id: article.id,
          ...article.attributes,
          ...article.relationships,
          images: articleImages[i]?.attributes?.uri,
        })
      }
    } else {
      // if we can't find the slug in articles, look in education_video
      const educationVideosResp = await fetch(
        `${DRUPAL_ENDPOINT}/education_videos?filter[field_article_slug]=${slug}&${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}`
      )
      const { data: educationVideosData, included: educationVideosIncluded } =
        await educationVideosResp.json()

      if (educationVideosData.length) {
        const educationVideoImages = educationVideosIncluded?.filter(
          (image) => image.type === 'file--file'
        )
        for (let i = 0; i < educationVideosData.length; i++) {
          const article = educationVideosData[i]
          articles.push({
            id: article.id,
            ...article.attributes,
            ...article.relationships,
            images: educationVideoImages[i]?.attributes?.uri,
          })
        }
      } else {
        // if we can't find a matching article nor video id, default to id
        const response = await fetch(
          `${DRUPAL_ENDPOINT}/articles/${id}?${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}`
        )
        const { data, included } = await response.json()
        const images = included?.filter((image) => image.type === 'file--file')

        const article = {
          id: data.id,
          ...data.attributes,
          ...data.relationships,
          images: images[0]?.attributes?.uri,
        }
        logger.info('article ', article)
        return article
      }
    }

    // the jsonapi article endpoint returns an array of 1, so return elmenet 0
    logger.info('article ', articles[0])
    return articles[0]
  } catch (e) {
    return e
  }
}

async function getRelatedArticlesByCategory(postId = 0, catagoryId = 0) {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const response = await fetch(
      `${DRUPAL_ENDPOINT}/articles?filter[id][value]=${postId}&filter[id][operator]=%3C%3E&${SORT_PARAM}&${MAX_RELATED_ARTICLE_PARAM}&${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}&jsonapi_include=5&filter[field_article_category.id][value]=${catagoryId}`
    )
    const { data: relatedPosts } = await response.json()
    logger.info('relatedCategoryPosts ', relatedPosts)

    return relatedPosts
  } catch (e) {
    return e
  }
}

async function getRelatedArticlesByTags(postId = 0, tagIds) {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    let tagsEndpoint = `${DRUPAL_ENDPOINT}/articles?filter[id][condition][path]=field_article_tags.id&filter[id][condition][operator]=IN`
    const maxIdTags = Math.min(tagIds.length, MAX_ID_TAGS)
    for (let i = 0; i < maxIdTags; i++) {
      tagsEndpoint += `&filter[id][condition][value][${i}]=${tagIds[i]}`
    }
    tagsEndpoint += `&${MAX_RELATED_ARTICLE_PARAM}&${SORT_PARAM}`
    const response = await fetch(tagsEndpoint)

    const { data } = await response.json()
    const relatedTagPosts = []
    for (let i = 0; i < data.length; i++) {
      const relatedTagPost = data[i]
      if (!postId || relatedTagPost.id !== postId) {
        relatedTagPosts.push({
          id: relatedTagPost.id,
          ...relatedTagPost.attributes,
          ...relatedTagPost.relationships,
        })
      }
    }
    logger.info('relatedTagPosts ', relatedTagPosts)
    return relatedTagPosts
  } catch (e) {
    return e
  }
}

async function getArticleCategory(catagoryId = 0) {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const response = await fetch(
      `${DRUPAL_ENDPOINT}/taxonomy_term/categories/${catagoryId}`
    )
    const { data: category } = await response.json()

    logger.info('category ', category)
    return category
  } catch (e) {
    return e
  }
}

async function getArticleCategories() {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const response = await fetch(`${DRUPAL_ENDPOINT}/taxonomy_term/categories`)
    const { data: categories } = await response.json()

    logger.info('categories ', categories)
    return categories
  } catch (e) {
    return e
  }
}

async function getArticleCategoryPosts(category, page = 1, perPage) {
  try {
    const categories = await getArticleCategories()

    const matchingCategory = categories?.find(
      ({ attributes }) => attributes.field_slug === category
    )

    if (matchingCategory === undefined) {
      return undefined
    } else {
      const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`

      const CATEGORY_ENDPOINT = `${DRUPAL_ENDPOINT}/articles?${SORT_PARAM}&page[limit]=${perPage}&page[offset]=${
        (page - 1) * perPage
      }&${INCLUDE_IMAGE_PARAM}&${FILE_URL_PARAM}&${JASON_INCLUDE_PARAM}&filter[field_article_category.id][value]=`
      const response = await fetch(`${CATEGORY_ENDPOINT}${matchingCategory.id}`)

      const { data: categoryArticleData, meta } = await response.json()
      return {
        postCount: meta?.count,
        posts: categoryArticleData,
      }
    }
  } catch (e) {
    return e
  }
}

async function getAboutUs() {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    let aboutUs
    const aboutUsResp = await fetch(`${DRUPAL_ENDPOINT}/about_us`)
    const { data: aboutUsData } = await aboutUsResp.json()

    // the jsonapi about_us endpoint returns an array of 1 or more, so return elmenet 0
    if (aboutUsData.length) {
      aboutUs = aboutUsData[0]
    }

    const leadershipMembers =
      aboutUs?.relationships?.field_leadership_member?.data

    if (leadershipMembers.length) {
      const maxLeadershipMember =
        aboutUs?.relationships?.field_leadership_member?.data?.length

      let leadershipCardsEndpoint = `${DRUPAL_ENDPOINT}/ten_x_leadership_cards?filter[id][condition][path]=id&filter[id][condition][operator]=IN`
      for (let i = 0; i < maxLeadershipMember; i++) {
        leadershipCardsEndpoint += `&filter[id][condition][value][${i}]=${leadershipMembers[i].id}`
      }
      leadershipCardsEndpoint += `&${INCLUDE_HEADSHOT_PARAM}`

      const leadershipCardsResp = await fetch(leadershipCardsEndpoint)
      const { data: leadershipCardsData, included } =
        await leadershipCardsResp.json()

      const images = included?.filter((image) => image.type === 'file--file')

      // create a map for Leadership cards
      const leadershipCardMap = {}
      if (leadershipCardsData?.length > 0) {
        leadershipCardsData.forEach((leadershipCardData, idx) => {
          leadershipCardMap[leadershipCardData.id] = {
            ...leadershipCardData,
            image: images[idx].attributes.uri.url,
          }
        })
      }

      // merged in leader member's information including images
      const fieldLeadershipMember = leadershipMembers.map(
        (leadershipMember) => ({
          ...leadershipMember,
          ...leadershipCardMap[leadershipMember.id],
        })
      )

      const updatedAboutUs = {
        id: aboutUs.id,
        ...aboutUs.attributes,
        ...aboutUs.relationships,
        field_leadership_member: fieldLeadershipMember,
      }
      logger.info('aboutUs ', updatedAboutUs)
      return updatedAboutUs
    }

    logger.info('aboutUs ', aboutUs)
    return aboutUs
  } catch (e) {
    return e
  }
}

async function getHelpContents() {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const helpContentsResp = await fetch(
      `${DRUPAL_ENDPOINT}/help_contents?${INCLUDE_IMAGE_PARAM}`
    )
    const { data: helpContentsData, included } = await helpContentsResp.json()

    const medias = included?.filter((image) => image.type === 'media--image')
    const files = included?.filter((image) => image.type === 'file--file')

    // create a map for the medias
    const mediaMap = {}
    if (medias?.length > 0) {
      medias.forEach((media) => {
        mediaMap[media.id] = {
          ...media,
        }
      })
    }

    // create a map for the files
    const fileMap = {}
    if (files?.length > 0) {
      files.forEach((file) => {
        fileMap[file.id] = {
          ...file,
        }
      })
    }

    // create a map for the helpContents
    const helpContents = {}
    if (helpContentsData?.length > 0) {
      helpContentsData.forEach((helpContentData) => {
        const mediaId =
          helpContentData?.relationships?.field_article_featured_image?.data?.id
        const imageId =
          mediaMap[mediaId]?.relationships?.field_media_image?.data?.id
        const image = fileMap[imageId]
        helpContents[helpContentData.id] = {
          ...helpContentData,
          image,
        }
      })
    }

    logger.info('helpContents', helpContents)
    return helpContents
  } catch (e) {
    return e
  }
}

async function getHelpTopics() {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    const helpTopicsResp = await fetch(`${DRUPAL_ENDPOINT}/help_topics`)
    const { data: helpTopicsData } = await helpTopicsResp.json()

    // create a map for the helpTopics
    const helpTopics = {}
    if (helpTopicsData?.length > 0) {
      helpTopicsData.forEach((helpTopicData) => {
        helpTopics[helpTopicData.id] = {
          ...helpTopicData,
        }
      })
    }

    logger.info('helpTopics', helpTopics)
    return helpTopics
  } catch (e) {
    return e
  }
}

async function getHelpCenter() {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    let helpCenter
    const helpCenterResp = await fetch(
      `${DRUPAL_ENDPOINT}/help_center?include=field_help_sections`
    )
    const { data: helpCenterData, included } = await helpCenterResp.json()

    // the jsonapi help_center endpoint returns an array of 1 or more, so return elmenet 0
    if (helpCenterData.length) {
      helpCenter = helpCenterData[0]
    }

    const helpSections = included?.filter(
      (helpSection) => helpSection.type === 'node--help_section'
    )

    const fieldHelpSections = []
    if (helpSections) {
      for (let i = 0; i < helpSections.length; i++) {
        const helpSection = helpSections[i]
        fieldHelpSections.push({
          id: helpSection.id,
          ...helpSection.attributes,
          ...helpSection.relationships,
        })
      }
    }

    const helpTopics = await getHelpTopics()
    const helpContents = await getHelpContents()

    const updatedHelpCenter = {
      id: helpCenter.id,
      ...helpCenter.attributes,
      ...helpCenter.relationships,
      field_help_sections: fieldHelpSections,
      field_help_topics: helpTopics,
      field_help_contents: helpContents,
    }
    logger.info('helpCenter ', updatedHelpCenter)
    return updatedHelpCenter
  } catch (e) {
    return e
  }
}

async function getLegalDocument(slug) {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    let legalDocument
    const legalDocsResp = await fetch(
      `${DRUPAL_ENDPOINT}/legal_documents?filter[field_article_slug]=${slug}`
    )
    const { data: legalDocsData } = await legalDocsResp.json()

    // the jsonapi about_us endpoint returns an array of 1 or more, so return elmenet 0
    if (legalDocsData.length) {
      legalDocument = legalDocsData[0]
    }

    logger.info('legalDocument ', legalDocument)
    return legalDocument
  } catch (e) {
    return e
  }
}

async function getFile(filename) {
  const DRUPAL_ENDPOINT = `${configment.get('DRUPAL_BASE_URL')}/jsonapi`
  try {
    let file
    const fileResp = await fetch(
      `${DRUPAL_ENDPOINT}/file/file?filter[filename][value]=${filename}`
    )
    const { data: fileData } = await fileResp.json()

    // the jsonapi about_us endpoint returns an array of 1 or more, so return elmenet 0
    if (fileData.length) {
      file = fileData[0]
    }

    logger.info('file ', file)
    return file
  } catch (e) {
    return e
  }
}

export default {
  // searchAutosuggestions,
  searchArticles,
  getArticleEducationVideos,
  getRecentArticles,
  getArticle,
  getRelatedArticlesByCategory,
  getRelatedArticlesByTags,
  getArticleCategory,
  getArticleCategories,
  getArticleCategoryPosts,
  getAboutUs,
  getHelpCenter,
  getLegalDocument,
  getFile,
}
