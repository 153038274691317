/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { withRouter, useParams } from 'react-router'
import configment from '@ten-x/configment'
import { IntegrationBanner, styles } from '@ten-x/fe-lib-ui2020'

import ConfirmBroker from '../../components/confirmBroker'
import { setBidderBrokerRegistration } from '../../data_providers/bidder'
// import AuthProvider from '../../data_providers/auth'
import MSG from '../../constants/messages'

export const PENDING = 'PENDING'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'

const { Banner } = IntegrationBanner

const { withThemeProvider } = styles

const ConfirmBrokerPage = ({ featureFlags }) => {
  const [confirmationStatus, setConfirmationStatus] = useState(PENDING)
  const { brokerRegistrationId } = useParams()

  useEffect(() => {
    let unmounted = false

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        const logoutUrl =
          configment.get('LIB_AUTH_PATH_PREFIX') + '/auth/logout'
        window.location.assign(logoutUrl)
      }
    }

    const confirmBiddersBroker = async () => {
      try {
        await setBidderBrokerRegistration(brokerRegistrationId)

        if (unmounted) return
        setConfirmationStatus(SUCCESS)
      } catch (err) {
        setConfirmationStatus(ERROR)
        console.error(err)
      }
    }

    confirmBiddersBroker()

    window.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      unmounted = true
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return (
    <Main>
      <Banner featureFlags={featureFlags} />
      <Helmet title={MSG.BROKER_CONFIRMATION} />
      <ConfirmBroker confirmationStatus={confirmationStatus} />
    </Main>
  )
}

ConfirmBrokerPage.propTypes = {
  featureFlags: PropTypes.object.isRequired,
}

const Main = styled.main(
  ({ theme }) => `
    background-color: ${theme.palette.grayLight};
    width: 100%;
    position: relative;
    min-height: 736px;

    padding: ${theme.spacing(0, 3)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `
)

const mapStateToProps = (store) => {
  return {
    featureFlags: store.config.featureFlags,
  }
}

const connectedConfirmBrokerPage = connect(mapStateToProps)(ConfirmBrokerPage)
export default withRouter(withThemeProvider(connectedConfirmBrokerPage))
