import React from 'react'
import { styles } from '@ten-x/fe-lib-ui2020'
import styled from '@emotion/styled'

import { Banner } from '../sections/banner'
import { events, HEADER } from '../../constants/about_us'

import milestone from './milestone.png'

const { withThemeProvider } = styles

const TimelineContainer = styled.div`
  position: relative;
  margin: 40px 0;
  padding: 0 10%;
  list-style: none;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 140px;
    width: 8px;
    background-color: #dcdcdc;
    transform: translateX(-50%);
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    padding: 0px 2%;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding: 0;
    &:before {
      content: none;
    }
  }
`

const FeaturedImage = styled.img`
  margin-bottom: 10px;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 3s ease-in-out;

  &:hover {
    transform: translateX(-50%) rotate(360deg);
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    transform: translateX(-50%);
    left: 50px;
    width: 40px;
    top: -46px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    width: 35px;
  }
`

const TimeLineDate = styled.div<{ isLeft: boolean }>`
  position: relative;
  width: ${({ isLeft }) => (isLeft ? '9%' : 'none')};
  color: ${({ theme }) => theme.palette.black};
  transform: ${({ isLeft }) =>
    isLeft ? 'translateX(100%)' : 'translateX(-100%)'};
  font-size: 2.1rem;
  font-weight: 300;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    top: -44px;
    transform: translateX(-100%);
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 25px;
  }
`

const TimelineItem = styled.div<{ isLeft: boolean }>`
  position: relative;
  width: 45%;
  padding: 0 10px;
  margin: 0;
  left: ${({ isLeft }) => (isLeft ? '0' : '55%')};
  transform: ${({ isLeft }) =>
    isLeft ? 'translateX(0)' : 'translateX(-100%)'};
  display: flex;
  justify-content: ${({ isLeft }) => (isLeft ? 'right' : 'left')};

  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    animation: bounce-in 0.6s ease-out;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.9);
    }
    60% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  h4 {
    margin: 0;
    color: black;
    font-weight: bold;
  }

  p {
    margin: 5px 0 0;
    color: #333;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    width: 90%;
    left: 20px;
    justify-content: left;
    font-size: 27px;

    &:before {
      left: 50%;
      transform: translateX(0);
    }
  }
`

const TimelineBox = styled.div<{ isLeft: boolean }>`
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.3);
  margin: 0;
  width: 500px;
  border-top: 10px solid #cf0000;
  padding: 10px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 28%;
    left: ${({ isLeft }) => (isLeft ? 'auto' : '-10px')};
    right: ${({ isLeft }) => (isLeft ? '-10px' : 'auto')};
    transform: translateY(-200%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: ${({ isLeft }) => (isLeft ? '10px solid white' : 'none')};
    border-right: ${({ isLeft }) => (isLeft ? 'none' : '10px solid white')};

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      display: none;
    }
  }
`

const ItemHeader = styled.div`
  font-size: 1.3rem;
  padding: 10px 20px;
  color: ${({ theme }) => theme.palette.black};

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 16px;
  }
`

const ItemStatement = styled.div`
  padding: 10px 20px;
  border-top: 1px solid grey;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 11px;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  position: relative;
  margin: 100px 0;
  &:last-child {
    background-color: white;
  }
`

const Timeline: React.FC = () => {
  React.useEffect(() => {
    const items = document.querySelectorAll('.timeline-item')
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible')
          }
        })
      },
      { threshold: 0.1 }
    )

    items.forEach((item) => observer.observe(item))

    return () => {
      items.forEach((item) => observer.unobserve(item))
    }
  }, [])

  return (
    <TimelineContainer>
      {events.map((event, index) => (
        <ItemWrapper key={event.year}>
          <FeaturedImage src={milestone} />
          <TimelineItem className="timeline-item" isLeft={index % 2 !== 0}>
            <TimelineBox isLeft={index % 2 !== 0}>
              <ItemHeader>{event.header}</ItemHeader>
              <ItemStatement>{event.statement}</ItemStatement>
            </TimelineBox>
          </TimelineItem>
          <TimeLineDate isLeft={index % 2 !== 0}>{event.year}</TimeLineDate>
        </ItemWrapper>
      ))}
    </TimelineContainer>
  )
}

const Revolution: React.FC = () => {
  return (
    <>
      <Banner
        header={HEADER.TITLE}
        description={HEADER.DESCRIPTION}
        descSize={HEADER.SIZE}
      />
      <Timeline />
    </>
  )
}

export default withThemeProvider(Revolution)
