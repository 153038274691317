/* eslint-disable no-nested-ternary */
import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { styles } from '@ten-x/fe-lib-ui2020'

import KNOWLEDGE_CENTER from '../../constants/knowledge_center'

import Revolution from './revolution'

const { withThemeProvider } = styles
export const AboutUsPage = () => {
  return (
    <Container>
      <Helmet>
        <title>{KNOWLEDGE_CENTER.ABOUT_US_TITLE}</title>
        <meta name="description" content="About Us" />
        <link rel="canonical" href={KNOWLEDGE_CENTER.ABOUT_US_CANONICAL} />
      </Helmet>
      <Revolution />
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;

  text-align: center;
  width: 100%;
`

export default withThemeProvider(AboutUsPage)
