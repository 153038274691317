import React from 'react'
import { Empty } from 'antd'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import configment from '@ten-x/configment'
import sanitizeHtml from 'sanitize-html'
import slugify from 'slugify'

import { truncateSummary } from '../../utils/format'

const ArticleItem = ({ category, post }) => {
  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')
  const DRUPAL_ENDPOINT = configment.get('DRUPAL_BASE_URL')
  const articleImage =
    post?.field_article_featured_image?.field_media_image?.uri?.url ||
    post?.images?.url
  const customAuthor = post?.field_article_custom_author
  const pureContent = truncateSummary(
    sanitizeHtml(post?.field_article_body?.processed || '', {
      allowedTags: [],
    })
  )
  const imageSrc = `${DRUPAL_ENDPOINT}${articleImage}`

  let slug = post?.field_article_slug
  if (!slug) {
    slug = `${slugify(post?.title, { lower: true })}/${post?.id}`
  }
  const articleUrl = `${category}/${slug}`
  const title = post?.title || ''

  return (
    <ArticleContainer>
      {articleImage ? (
        <ArticleImage imageSrc={imageSrc} />
      ) : (
        <NoImage>
          <Empty
            style={{
              alignSelf: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
            description="No Image"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </NoImage>
      )}
      <ArticleContentContainer>
        <a href={`${KNOWLEDGE_CENTER_URL}/${articleUrl}`}>
          <ArticleTitle
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(title.split('--')[0]),
            }}
          />
        </a>
        <ArticleContent
          dangerouslySetInnerHTML={{
            __html: pureContent,
          }}
        />
        <ArticleDivider />
        <p>By {customAuthor || 'Ten-X'}</p>
      </ArticleContentContainer>
    </ArticleContainer>
  )
}

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  font-size: 16px;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: row;
    margin: 40px 0;
  }
`

const ArticleImage = styled.div`
  align-items: center;
  background-image: url('${(props) => props.imageSrc}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 300px;
  min-width: 35%;
  width: 100%;
  h3 {
    color: white;
    font-weight: 400;
    font-size: 22px;
    margin-left: 20px;
    margin-bottom: 0;

    @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      font-size: 40px;
      margin-left: 60px;
    }

    @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      font-size: 60px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    min-height: ${(props) => (props.helpCenter ? '300px' : 'unset')};
    height: 240px;
    max-width: ${(props) => (props.helpCenter ? 'unset' : '30%')};
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    height: 300px;
  }
`

const NoImage = styled.div`
  background-color: ${({ theme }) => theme.palette.grayLight};
  display: flex;
  justify-content: center;
  min-height: 160px;
  min-width: 35%;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    height: 240px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    height: 300px;
  }
`

const ArticleContentContainer = styled.div`
  color: rgba(136, 136, 136, 1);
  font-weight: 300;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const ArticleTitle = styled.h3`
  color: #595959;
  font-size: 20px;
  font-weight: 300;

  :hover {
    color: #1890ff;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 24px;
    line-height: 24px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 30px;
    line-height: 30px;
  }
`

const ArticleContent = styled.div``

const ArticleDivider = styled.hr`
  border: 1px solid rgba(85, 85, 85, 1);
  margin-left: 0;
  width: 50%;
`

const PostObject = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      rendered: PropTypes.string,
    }),
  ]),
  content: PropTypes.shape({
    rendered: PropTypes.string,
  }),
  excerpt: PropTypes.shape({
    rendered: PropTypes.string,
  }),
  sticky: PropTypes.bool,
}

ArticleItem.propTypes = {
  category: PropTypes.string,
  post: PropTypes.shape(PostObject),
}

export default ArticleItem
