export const BANNERS = {
  POWER_ONLINE: {
    TITLE: 'LOOPNET AND TEN-X TO POWER ONLINE CRE TRANSACTIONS',
    DESCRIPTION: `Ten-X is the #1 transaction technology powering commercial real estate sales online. We've united with LoopNet to deliver the most expansive pool of buyers and real-time asset intelligence powered by CoStar data. Together, we make transactions twice as fast and twice as certain for brokers, owners, and investors.`,
    SIZE: '67',
  },
  ALL_INVESTORS: {
    TITLE: 'INVESTORS OF ALL SIZES SEARCH LOOPNET AUCTIONS EVERY MONTH',
    IMAGES: [
      {
        src: '/images/homepage/logos/the_walt_disney_company.png',
        alt: 'Walt Disney Company',
      },
      {
        src: '/images/homepage/logos/walmart.png',
        alt: 'Walmart',
      },
      {
        src: '/images/homepage/logos/viacom.png',
        alt: 'Viacom',
      },
      {
        src: '/images/homepage/logos/ups.png',
        alt: 'UPS',
      },
      {
        src: '/images/homepage/logos/travelers.png',
        alt: 'Travelers',
      },
      {
        src: '/images/homepage/logos/southwest.png',
        alt: 'South West Airlines',
      },
      {
        src: '/images/homepage/logos/the_hartford.png',
        alt: 'The Harford',
      },
      {
        src: '/images/homepage/logos/texas_instruments.png',
        alt: 'Texas Instruments',
      },
      {
        src: '/images/homepage/logos/target.png',
        alt: 'Target',
      },
      {
        src: '/images/homepage/logos/unitedhealthcare.png',
        alt: 'United Health Care',
      },
      {
        src: '/images/homepage/logos/starbucks.png',
        alt: 'Starbucks',
      },
      {
        src: '/images/homepage/logos/pfizer.png',
        alt: 'Pfizer',
      },
    ],
    SIZE: '35',
  },

  HELP_BROKERS: {
    TITLE: 'HELP BROKERS, OWNERS, AND INVESTORS TRANSACT CRE FASTER',
    DESCRIPTION: `Our customized marketing solutions use best-in-class technology to put your listing in the best position to find your perfect tenant or buyer.`,
    SIZE: '41',
  },

  CRE_AUCTION: {
    TITLE: 'CRE AUCTION NEWS, INSIGHTS, EDUCATION AND STORIES',
    DESCRIPTION:
      'Stay up to date on the commercial real estate landscape with engaging content from our experts. Explore CRE insights, educational articles, recent success stories, and more.',
    SIZE: '44',
  },
}
