export default {
  TITLE: 'Learn About Online Commercial Real Estate Auctions',
  DESCRIPTION:
    'Our Knowledge Center is full of CRE insights, educational articles, recent success stories, and more. Stay up to date on the commercial real estate industry with content from the experts.',
  CANONICAL: 'https://www.ten-x.com/knowledge-center',

  EDUCATION_TITLE:
    'How To Buy & Sell CRE Online – Auction Guides and Education',
  EDUCATION_DESCRIPTION:
    'See how to buy and sell commercial properties on Ten-X. Watch educational videos and read our guides to registering to bid, listing a property, bidding online, and closing. Learn everything you need to know about online auctions.',

  INSIGHTS_TITLE: 'Commercial Real Estate Industry News and Insights',
  INSIGHTS_DESCRIPTION:
    'Get the latest updates on the commercial real estate market. Read CRE news and insights from across the United States. Support your investments with the latest CRE market data.',

  NEWS_TITLE: 'Latest Online Auction & CRE Industry News',
  NEWS_DESCRIPTION:
    'What is happening in the world of commercial real estate today? Read the latest headlines on Ten-X and the CRE landscape. Join the conversation with the newest developments of online auctions.',

  SUCCESS_STORIES_TITLE: 'Inside the Online CRE Auction Experience',
  SUCCESS_STORIES_DESCRIPTION:
    'See why thousands of brokers, buyers, and sellers across the U.S. choose to accelerate their transactions with the #1 digital CRE auction platform. Read testimonials from commercial real estate industry leaders about their online auction experience. Explore reviews of the online auction process from our partners and customers.',

  HELP_CENTER_TITLE: 'Help Center',
  HELP_CENTER_DESCRIPTION:
    'We’re here to assist you throughout the entire process of buying and selling commercial real estate on the Ten-X Commercial marketplace. Browse through the categories below for information on some key topics.',
  HELP_CENTER_CANONICAL: 'https://www.ten-x.com/help',

  ABOUT_US_TITLE: 'About Us',
  ABOUT_US_DESCRIPTION:
    'MORE THAN A DECADE OF EXPERIENCE FACILITATING CRE TRANSACTIONS',
  ABOUT_US_CANONICAL: 'https://www.ten-x.com/about-ten-x',
}

export const BANNERS = {
  AUCTION_NEWS: {
    TITLE: 'CRE AUCTION NEWS, INSIGHTS, EDUCATION AND STORIES',
    DESCRIPTION: `Stay up to date on the commercial real estate lanscape with engaging content from our experts. Explore CRE insights, educational articles, recent success stories, and more!`,
  },
}
