import React, { useState } from 'react'
import styled from '@emotion/styled'

import Quotes from '../../assets/quotes.svg'
import playButton from '../../assets/playbutton.svg'

export const Container = styled.div`
  height: 580px;
  display: flex;
  margin: 100px 10%;
  border-radius: 0px 0px 8px 0px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  &:first-of-type {
    margin-top: 30px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    margin: 100px 2%;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    flex-direction: column;
    margin: 50px 2%;
  }
`

const TopRedLine = styled.div`
  height: 10px;
  background-color: #d32f2f;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const ImageContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 440px;
  background-image: url('/images/homepage/james-nelson.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    max-width: none;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex: 2;
  }
`
const QuotesImage = styled.div`
  background-image: url(${Quotes});
  background-repeat: no-repeat;
  min-height: 50px;
  margin-bottom: 1%;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    margin-bottom: 0px;
  }
`

const ContextContainer = styled.div`
  flex: 1;
  padding: 50px 20px 20px 50px;
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    margin-top: 5%;
    padding: 20px;
    font-size: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding: 20px;
    font-size: 1rem;
  }
`
const PlayerButton = styled.img`
  width: 120px;
  transition: transform 0.3s ease, font-weight 0.3s ease;
  font-weight: normal;

  &:hover {
    transform: scale(1.1);
    font-weight: bold;
  }
`

const Signature = styled.div`
  font-weight: normal;
  font-size: 1.5rem;
  display: none;
  text-align: right;
  padding-right: 45px;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-weight: 300;
    padding-top: 5%;
    display: block;
  }
`

export const VideoPlayer = React.memo(({}) => {
  const [isVideoVisible, setVideoVisible] = useState(false)

  const handlePlayClick = () => {
    setVideoVisible(true)
  }

  const handleCloseClick = () => {
    setVideoVisible(false)
  }

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setVideoVisible(false)
      }
    }

    const handleClickOutside = (event: MouseEvent) => {
      const container = document.getElementById('video-player-container')
      if (!container) {
        setVideoVisible(false)
      }
    }

    if (isVideoVisible) {
      window.addEventListener('keydown', handleKeyDown)
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isVideoVisible])

  return (
    <Container>
      <TopRedLine />

      <ImageContainer>
        {!isVideoVisible && (
          <PlayerButton onClick={handlePlayClick} src={playButton} />
        )}
      </ImageContainer>
      {!isVideoVisible && (
        <ContextContainer>
          <QuotesImage />I think a great fit for the Ten-X platform is when you
          need certainty of execution and a defined timeframe. What's been
          amazing working with the Ten-X team is that every year it seems you
          just continue to step up your game and improve on the technology.
          <Signature>
            James Nelson | Principal & Head of Tri-State Investment Sales |
            Avison Young
          </Signature>
        </ContextContainer>
      )}

      {isVideoVisible && (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/z2730x__G1M?si=GvtDxdbIuzFnL1a4&autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          <button
            onClick={handleCloseClick}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              padding: '5px 10px',
              cursor: 'pointer',
            }}
          >
            Close X
          </button>
        </div>
      )}
    </Container>
  )
})
