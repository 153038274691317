import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import isEqual from 'lodash/isEqual'

class NotificationContainer extends Component {
  componentDidUpdate(prevProps) {
    const { sharedMessage } = this.props
    if (
      sharedMessage &&
      !isEqual(prevProps.sharedMessage, sharedMessage) &&
      sharedMessage.isVisible
    ) {
      const notificationType =
        (sharedMessage.messageType &&
          notification[sharedMessage.messageType]) ||
        notification.open
      notificationType({
        message: sharedMessage.title,
        description: sharedMessage.message,
      })
    }
  }

  render() {
    return <div data-elm-id="notification-container" />
  }
}

NotificationContainer.propTypes = {
  sharedMessage: PropTypes.object,
}

export default NotificationContainer
