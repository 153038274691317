import { gtm } from '@ten-x/app-analytics'
import md5 from 'md5'

import store from '../store'

function _onPageLoad() {
  const [userData] = _getUserData()
  gtm.trackEvent('page_view', {
    user_data: userData,
  })
}

function _getUserData() {
  const state = store().getState()
  const { user } = state
  return [
    {
      user_id: user && user.user_id,
      contact_id: user && user.contactId,
      user_login_status: !!(user && user.logged_in),
    },
    user,
  ]
}

function onPageLoadHome() {
  _onPageLoad()
  _getUserData()
}

function traceEvent(eventName) {
  const state = store().getState()
  const { user } = state

  const trackVars = {}

  if (user.contactId && user.sub) {
    trackVars.contactId = user.contactId
    trackVars.sub = user.sub
    trackVars['tenx.cre.md5_user_email'] = user.email ? md5(user.email) : ''
  }

  _onPageLoad()

  gtm.trackEvent(eventName, trackVars)
}

function onPageLoadHelpCenter() {
  traceEvent('Help Center Viewed')
}

function onPageLoadKnowledgeCenter() {
  traceEvent('Knowledge Center Viewed')
}

function onPageLoadLandingPage(pageType) {
  traceEvent(`${pageType} Page Viewed`)
}

function onLearnMore() {
  gtm.trackEvent('Learn More')
}

function onScheduleADemo() {
  gtm.trackEvent('Schedule a Demo')
}

function onViewAllTenXListingsOnLoopNet() {
  gtm.trackEvent('View All Ten-X Listings on LoopNet')
}

export default {
  onPageLoadHome,
  onPageLoadHelpCenter,
  onPageLoadKnowledgeCenter,
  onPageLoadLandingPage,
  onLearnMore,
  onScheduleADemo,
  onViewAllTenXListingsOnLoopNet,
}
