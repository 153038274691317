import styled from '@emotion/styled'
import { Row } from 'antd'

export const TransactHeader = styled.div<{ colorBg?: string }>`
  background-color: ${(props) => props.colorBg ?? '#fff'};
  padding: 75px 25px 25px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    padding: 15px 10px;
  }
`

export const MainHeader = styled.div`
  font-size: 2rem;
  padding-bottom: 15px;
  color: ${({ theme }) => theme.palette.black};
  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 1.1rem;
  }
`

export const Description = styled.div<{ descSize?: string }>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 1.1rem;
  font-weight: 300;
  max-width: ${({ descSize }) => descSize}%;
  padding: 0 20px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    max-width: none;
  }
`

export const RedBorderLine = styled.div`
  width: 0;
  height: 1px;
  background-color: #cf0000;
  margin: 20px auto;
  transition: width 0.6s linear(0.79 79.74%, 1 100%);
  opacity: 0;
  &.visible {
    width: 300px;
    opacity: 1;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    margin: 10px auto;
  }
`

export const ImageContainer = styled(Row)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
  box-sizing: border-box;
  justify-content: center;

  & > * {
    flex: 1 1 calc(100% / 6);
    max-width: calc(100% / 6);
    box-sizing: border-box;
    @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
      flex: 1 1 calc(100% / 3);
      max-width: calc(100% / 3);
      min-height: 70%;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    min-height: none;

    & > * {
      flex: 1 1 calc(100% / 2);
      max-width: calc(100% / 2);
    }
  }
`

export const LogoImage = styled.img`
  height: 30px;
  margin: 10px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    height: 20px;
  }

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
`
export const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;

  & > img {
    max-width: 100%;
    height: auto;
    flex: 1 1 auto;
    margin: 5px;
  }
`
