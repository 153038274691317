import React, { useEffect, useRef } from 'react'
import { Col } from 'antd'

import {
  TransactHeader,
  Description,
  MainHeader,
  RedBorderLine,
  ImageContainer,
  LogoImage,
  LogoWrapper,
} from '../styles'

type IProps = {
  colorBg?: string
  header: string
  description?: string
  images?: { src: string; alt: string }[]
  descSize?: string
}

export const Banner: React.FC<IProps> = ({
  colorBg,
  header,
  description,
  images = [],
  descSize = '500px',
}) => {
  const redBorderLineRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible')
          }
        })
      },
      { threshold: 0.1 }
    )

    if (redBorderLineRef.current) {
      observer.observe(redBorderLineRef.current)
    }

    return () => {
      if (redBorderLineRef.current) {
        observer.unobserve(redBorderLineRef.current)
      }
    }
  }, [])

  return (
    <>
      <TransactHeader colorBg={colorBg}>
        <MainHeader>{header}</MainHeader>
        <RedBorderLine ref={redBorderLineRef} />
        {description && (
          <Description descSize={descSize}>{description}</Description>
        )}
        {images && (
          <ImageContainer>
            {images.map((logo, index) => {
              return (
                <LogoWrapper key={index}>
                  <LogoImage src={logo.src} alt={logo.alt} />
                </LogoWrapper>
              )
            })}
          </ImageContainer>
        )}
      </TransactHeader>
    </>
  )
}
