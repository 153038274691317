export const HEROS = {
  KNOWLEDGE_CENTER: {
    VIDEO: undefined,
    TEXT: 'Commercial real estate auction news, insights, education and stories',
    IMAGE: '/images/homepage/conference-meeting.png',
  },
  ABOUT_US: {
    VIDEO: '/images/homepage/hero_video3.mp4',
    IMAGE: '/images/homepage/about-us-static.png',
    TEXT: 'More than a decade of experience facilitating cre transactions',
  },
  HOME_PAGE: {
    VIDEO: '/images/homepage/hero_video2.mp4',
    IMAGE: '/images/homepage/home-static.png',
    TEXT: 'Empowering Faster, Smarter Commercial Real Estate Transactions',
  },
  LEARN_MORE: {
    VIDEO: '/images/homepage/hero_video1.mp4',
    IMAGE: '/images/homepage/learn-more-static.png',
    TEXT: 'ACCELERATE YOUR TRANSACTION and maximize your results',
    DEMO_BUTTON: true,
  },
  GENERIC: {
    TEXT: 'Commercial real estate auction news, insights, education and stories',
  },
}
