import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import configment from '@ten-x/configment'
import _get from 'lodash/get'

import App from './app'
import Home from './containers/home'
import KnowledgeCenter from './containers/knowledgeCenter'
import Category from './containers/category'
import AboutUsPage from './containers/aboutUsPage'
import HelpPage from './containers/helpPage'
import ArticlePage from './containers/articlePage'
import SearchPage from './containers/searchPage'
import Layout from './containers/layout'
import LegalPage from './containers/legalPage'
import LearnMore from './containers/learnMore'
import Files from './containers/Files'
import LandingPage from './containers/landing-page'
import ConfirmBroker from './containers/confirmBroker'
import PrivateRoute from './containers/privateRoute'
import SDKTest from './containers/SDKTest'
import { HEROS } from './constants/hero'
import BROKER_CONSTANTS from './constants/broker_constants'
import BUYER_CONSTANTS from './constants/buyer_constants'
import { HEADING_TYPES } from './constants/enums'

Routes.propTypes = {
  featureFlags: PropTypes.object.isRequired,
  user: PropTypes.object,
}
const isProd = () => configment.get('ENV') === 'prd'

function Routes({ featureFlags, user }) {
  const isPoweredByTenX = _get(featureFlags, 'fe.cre.powered-by-tenx', true)
  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')

  return (
    <App>
      <Switch>
        {/* Homepage */}
        <Route exact path={['/']}>
          <Layout heroType={HEROS.HOME_PAGE} noSpacing>
            <Home />
          </Layout>
        </Route>

        {/* The following route is only meant to be accesible outside of PRD environment to test SDK functionalities */}
        {!isProd() && isPoweredByTenX && (
          <Route exact path="/test-sdk" component={SDKTest} />
        )}

        <Route exact path="/sell-commercial-real-estate">
          <Layout heroType={HEROS.LEARN_MORE} noSpacing>
            <LearnMore />
          </Layout>
        </Route>

        {!isPoweredByTenX && (
          <>
            {/* Brokers & Buyers */}
            <Route exact path="/broker-commercial-real-estate">
              <Layout noSpacing darkFont showHero={false}>
                <LandingPage
                  PAGE_CONSTANTS={BROKER_CONSTANTS}
                  H1={HEADING_TYPES.HERO_HEADING}
                  pageType="Brokers"
                />
              </Layout>
            </Route>
            <Route exact path="/buy-commercial-real-estate">
              <Layout noSpacing darkFont showHero={false}>
                <LandingPage
                  noForm
                  PAGE_CONSTANTS={BUYER_CONSTANTS}
                  H1={HEADING_TYPES.HERO_SUBTITLE}
                  pageType="Buyers"
                />
              </Layout>
            </Route>

            {/* Help Center */}
            <Route exact path="/company/commercial/help">
              <Layout>
                <HelpPage />
              </Layout>
            </Route>
            <Route exact path="/help">
              <Layout>
                <HelpPage />
              </Layout>
            </Route>
          </>
        )}

        {/* Legal Documents */}
        <Route exact path="/company/:pathSlug">
          <Layout>
            <LegalPage />
          </Layout>
        </Route>
        <Route exact path="/company/legal/:slug">
          <Layout>
            <LegalPage />
          </Layout>
        </Route>
        <Route exact path="/company/legal/:slug/archives">
          <Layout>
            <LegalPage isArchives />
          </Layout>
        </Route>

        {/* Drupal files */}
        <Route exact path="/uploads/:filename">
          <Layout>
            <Files />
          </Layout>
        </Route>

        {/* WP files */}
        <Route exact path="/uploads/:year/:month/:filename">
          <Layout>
            <Files />
          </Layout>
        </Route>
        <Route exact path="/wp-content/uploads/:year/:month/:filename">
          <Layout>
            <Files />
          </Layout>
        </Route>

        {/* Marketing Agreement */}
        <Route exact path="/Ten-XMA-Standard-Terms">
          <Layout>
            <LegalPage slugId="ten-x-marketing-agreement-standard-terms" />
          </Layout>
        </Route>

        {/* Archived Marketing Agreement */}
        <Route exact path="/Archived-Ten-XMA-Standard-Terms">
          <Layout>
            <LegalPage slugId="previous-ten-x-marketing-agreement-standard-terms" />
          </Layout>
        </Route>

        {/* About Ten-X / Leadership */}
        <Route exact path="/about-ten-x">
          <Layout noSpacing heroType={HEROS.ABOUT_US}>
            <AboutUsPage />
          </Layout>
        </Route>

        {/* Knowledge Center */}
        <Route exact path={`${KNOWLEDGE_CENTER_URL}`}>
          <Layout heroType={HEROS.KNOWLEDGE_CENTER} showHeroText showSearch>
            <KnowledgeCenter />
          </Layout>
        </Route>
        <Route exact path={`${KNOWLEDGE_CENTER_URL}/search`}>
          <Layout heroType={HEROS.KNOWLEDGE_CENTER} showSearch>
            <SearchPage />
          </Layout>
        </Route>
        <Route exact path={`${KNOWLEDGE_CENTER_URL}/:category`}>
          <Layout heroType={HEROS.KNOWLEDGE_CENTER} showSearch>
            <Category />
          </Layout>
        </Route>
        <Route exact path={`${KNOWLEDGE_CENTER_URL}/:category/:slug`}>
          <Layout heroType={HEROS.KNOWLEDGE_CENTER} showSearch>
            <ArticlePage />
          </Layout>
        </Route>
        <Route exact path={`${KNOWLEDGE_CENTER_URL}/:category/:slug/:id`}>
          <Layout heroType={HEROS.KNOWLEDGE_CENTER} showSearch>
            <ArticlePage />
          </Layout>
        </Route>

        <PrivateRoute
          path={[
            '/user/confirm-broker/:brokerRegistrationId',
            '/commercial/user/confirm-broker/:brokerRegistrationId',
          ]}
          component={ConfirmBroker}
        />

        {/* Default - Redirect to Homepage */}
        <Route>
          <Layout heroType={HEROS.HOME_PAGE} noSpacing>
            <Home />
          </Layout>
        </Route>
      </Switch>
    </App>
  )
}

function mapStateToProps(store) {
  return {
    user: store.user,
    featureFlags: store.config.featureFlags,
  }
}

export default connect(mapStateToProps)(Routes)
