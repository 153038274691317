/* istanbul ignore file */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Input } from 'antd'

const SdkRequestCard = ({ title, sdkFunction, params, description }) => {
  const [fetchDataResponse, setFetchDataResponse] = useState()
  const [fetching, setFetching] = useState(false)

  const callFunction = async () => {
    setFetching(true)
    let response
    try {
      response = await sdkFunction()
    } catch (error) {
      response = (error.message && { error: error.message }) || error
    }
    setFetching(false)
    setFetchDataResponse(JSON.stringify(response, undefined, 4))
  }

  return (
    <Card
      title={title}
      extra={
        <Button onClick={callFunction} disabled={fetching}>
          Make request
        </Button>
      }
    >
      <h2>Description</h2>
      {description}
      {(params.length && <h2>Request Parameters</h2>) || ''}
      {params.map((param, key) => (
        <div key={key}>
          {param.name}
          <Input
            value={param.value}
            disabled={fetching}
            onChange={param.onChange}
          />{' '}
        </div>
      ))}
      {fetchDataResponse && (
        <div header="Response" key="1">
          <h2>Response</h2>
          <pre>{fetchDataResponse}</pre>
        </div>
      )}
    </Card>
  )
}

SdkRequestCard.propTypes = {
  title: PropTypes.string,
  sdkFunction: PropTypes.func,
  params: PropTypes.array,
  description: PropTypes.node,
}

const SdkRequestComponent = (props) => {
  const [state, setState] = useState({})

  const sdkFunction = () => {
    const withParamParsers = props.paramParsers
    const params = props.params.map((param, index) => {
      if (withParamParsers) {
        const parser = props.paramParsers[index]
        if (parser) {
          return parser(state[param])
        }
      }
      return state[param]
    })
    return props.sdkFunction(...params)
  }

  const params = props.params.map((param) => ({
    name: param,
    value: state[param],
    onChange: (e) => {
      const { value } = e.target
      setState({
        ...state,
        [param]: value,
      })
    },
  }))

  return (
    <SdkRequestCard
      title={props.title}
      sdkFunction={sdkFunction}
      params={params}
      description={props.description}
    />
  )
}

SdkRequestComponent.propTypes = {
  title: PropTypes.string,
  sdkFunction: PropTypes.func,
  params: PropTypes.array,
  paramParsers: PropTypes.array,
  description: PropTypes.node,
}

export default SdkRequestComponent
