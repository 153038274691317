import React from 'react'
import styled from '@emotion/styled'
import { Col } from 'antd'

const KnowledgeCenter: React.FC = () => {
  const handleSeeAllClick = () => {
    window.location.href = '/knowledge-center'
  }

  const images = [
    {
      src: '/images/homepage/knowledge-center-auction-expert.png',
      alt: 'Auction Export',
      title:
        'Auction eXperts | James Nelson - Principal & Head of Tri-State Investment Sales, Avison Young',
    },

    {
      src: '/images/homepage/knowledge-center-shaking-hands.png',
      alt: 'Contracts & Closing Guide',
      title: 'Contracts & Closing Guide',
    },
    {
      src: '/images/homepage/knowledge-center-twotone-building.png',
      alt: 'Success Stories',
      title: 'Auction Success Stories',
    },
    {
      src: '/images/homepage/knowledge-center-building.png',
      alt: 'Building the Deals',
      title: 'Building the Deals: Barrington One',
    },
  ]
  return (
    <Container>
      <Header>KNOWLEDGE CENTER</Header>
      <ImageGrid>
        {images.map((image, index) => (
          <ImageCard key={index} cardPosition={index}>
            <div style={{ position: 'relative' }}>
              <Image src={image.src} alt={image.alt} />
              <ImageTitle>{image.title}</ImageTitle>
            </div>
          </ImageCard>
        ))}
      </ImageGrid>
      <ButtonWrapper>
        <SeeAllButton onClick={handleSeeAllClick}>See All</SeeAllButton>
      </ButtonWrapper>
    </Container>
  )
}

export default KnowledgeCenter

const Container = styled.div`
  text-align: center;
  padding: 100px 20px;
`

const Header = styled.h2`
  font-size: 2rem;
  padding-bottom: 15px;
  color: ${({ theme }) => theme.palette.black};
  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 1.1rem;
  }
`

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`

const ImageCard = styled(Col)<{ cardPosition: number }>`
  flex: 1 1 calc(25% - 20px);
  max-width: calc(25% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    line-height: 3vw;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    line-height: 7vw;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    flex: 1 1 100%;
    max-width: 100%;
    line-height: 1.3;
    display: ${({ cardPosition }) => (cardPosition > 1 ? 'none' : 'inline')};
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

const ImageTitle = styled.div`
  padding: 10px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 1rem;
  }
`
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const SeeAllButton = styled.button`
  background-color: #d0021b;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 30px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  width: ${({ width }) => width || '200px'};
`
