import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { HEROS } from '../../constants/hero'

import Search from './search'

const Hero = ({
  showHeroText,
  showSearch = false,
  heroImage = '',
  heroVideo,
  heroText = HEROS.GENERIC.TEXT,
  demoButton = false,
}) => {
  return (
    <HeroContainer hasVideo={heroVideo} heroImage={heroImage}>
      {heroVideo && (
        <HeroVideo autoPlay loop muted playsInline src={heroVideo} />
      )}

      {
        <TextContainer showSearch={showSearch}>
          <HeroText>
            {heroText}

            {demoButton && (
              <DemoButtonContainer>
                <DemoButton
                  onClick={() => {
                    const demoElement = document.getElementById('demoform')
                    if (demoElement) {
                      demoElement.scrollIntoView({ behavior: 'smooth' })
                    }
                  }}
                >
                  Schedule a Demo
                </DemoButton>
              </DemoButtonContainer>
            )}
          </HeroText>
        </TextContainer>
      }

      {showSearch && (
        <SearchContainer>
          <Search />
        </SearchContainer>
      )}
    </HeroContainer>
  )
}

Hero.propTypes = {
  showHeroText: PropTypes.bool,
  showSearch: PropTypes.bool,
  heroImage: PropTypes.string,
  heroVideo: PropTypes.string,
  heroText: PropTypes.string,
  demoButton: PropTypes.bool,
}

export default Hero

const HeroContainer =
  styled.div <
  { hasVideo: Boolean, heroImage: String } >
  `
  position: relative;
  width: 100%;
  height: 580px;
  max-height: 700px;
  min-height: 500px;
  background-image: ${({ hasVideo, heroImage }) =>
    hasVideo ? 'none' : `url(${heroImage})`};
  background-size: cover;
  background-position: ${({ hasVideo }) => (hasVideo ? 'left' : 'center')};;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  background-color: ${({ hasVideo }) => (hasVideo ? 'none' : 'black')};
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  max-width: 120rem;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    background-image: ${({ heroImage }) =>
      heroImage ? `url(${heroImage})` : 'none'};
    height: 550px;
    max-height: 600px;
    background-color: #000;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90px), linear-gradient(to left, rgba(0, 0, 0, .9), rgba(0, 0, 0, 0));
    background-position: center right;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 112%;
    height: 130px;
    background-color: white;
    background-image: linear-gradient(90deg, #0054a6 -3%, #ce2027 81%);
    background-size: 38% 12px;
    background-repeat: no-repeat;
    background-position: center -3px;
    border-radius: 42%;
    transform: translateX(-50%) translateY(50%);
    box-shadow: inset 0 1px 7px rgba(0, 0, 0, 0.5);

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
      width: 141%;
      max-height: 700px;
    }

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      background-size: 42% 8px;
      border-radius: 0;
    }
  }
`

const TextContainer =
  styled.div <
  { showSearch: Boolean } >
  `
  display: flex;
  justify-content: right;
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 64px;
  transform: ${({ showSearch }) =>
    showSearch ? 'translateY(-70%)' : 'translateY(-50%)'};
  position: relative;
  top: 50%;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    padding: 0 45px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding: 0 24px;
  }
`

const HeroText = styled.h1`
  font-size: min(calc(2.5rem + 1vw), 3.75rem);
  line-height: 1.2;
  color: white;
  width: 808px;
  z-index: 1;
  text-transform: uppercase;
  min-width: 240px;
  text-align: right;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: calc(1.5rem + 2vw);
    width: 490px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: calc(1.2rem + 2vw);
    width: 324px;
  }
`
const DemoButtonContainer = styled.div`
  width: 100%;
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  transform: translateX(0%) translateY(-250%);
`
const HeroVideo = styled.video`
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 640px;
  height: 100%;
  object-fit: cover;
  z-index: -1;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    display: none;
  }
`

const DemoButton = styled.button`
  background-color: #d0021b;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  width: 200px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    display: none;
  }
`
