import React, { useEffect, useState } from 'react'
import * as SDK from '@ten-x/fe-lib-sdk'
import configment from '@ten-x/configment'
import { Button, Card, Input, Select, Row, Col } from 'antd'
import _get from 'lodash/get'

import SubscriptionCard from './subscriptionCard'

const { Option } = Select

const ServerManagement = () => {
  const env = configment.get('ENV')
  const [targetUrl, setTargetUrl] = useState(
    `wss://www-${(env === 'dev' && 'dvm') || env}.ten-x.com/bidding`
  )
  const [targetNamespace, setTargetNamespace] = useState(
    '/ws-bidding/socket.io'
  )

  useEffect(() => {
    SDK.config.realtime.setRealtimeConfiguration(targetUrl, targetNamespace)
  }, [])

  const makeRequest = () => {
    if (
      targetUrl &&
      setTargetUrl.length &&
      targetNamespace &&
      targetNamespace.length
    ) {
      SDK.config.realtime.setRealtimeConfiguration(targetUrl, targetNamespace)
    }
  }

  return (
    <Card
      title="Set Realtime Server URLs"
      extra={
        <Button onClick={makeRequest} disabled={false}>
          Make request
        </Button>
      }
    >
      <h2>Description</h2>
      <p>
        Sets the URL and namespace for realtime connection <br />
        This function must be called before attempting to instantiate <br />
        the socket connection. Otherwise it will fail. <br />
        <b style={{ color: 'red' }}>WARNING:</b> modifying this can cause socket
        connections to fail in this page
      </p>
      <h2>Request Parameters</h2>
      <Row gutter={4}>
        <Col xs={12}>
          <Input
            value={targetUrl}
            placeholder="Select the target url"
            onChange={(event) => {
              setTargetUrl(event.target.value)
            }}
          />
        </Col>
        <Col xs={12}>
          <Input
            value={targetNamespace}
            placeholder="Select the target namespace"
            onChange={(event) => {
              setTargetNamespace(event.target.value)
            }}
          />
        </Col>
      </Row>
    </Card>
  )
}

const RealTimeConfig = () => {
  return (
    <>
      <ServerManagement />
    </>
  )
}

const RealtimeTest = () => {
  const [subscriptionGroup, setSubscriptionGroup] = useState([{}])

  useEffect(() => {
    return () => {
      subscriptionGroup.forEach(destroySDKSubscription)
    }
  }, [])

  const createSubscription = (subscriptionId, newSubscriptionInfo) => {
    const subscriptionSDKResult = SDK.realtime.subscribe(newSubscriptionInfo)

    setSubscriptionGroup((subGroups) => {
      subGroups[subscriptionId].data = subscriptionSDKResult
      return [...subGroups]
    })
  }

  const createNewSubscriptionGroup = () => {
    const newSubscriptionGroup = {
      name: `Subscription ${subscriptionGroup.length + 1}`,
    }
    setSubscriptionGroup([...subscriptionGroup, newSubscriptionGroup])
  }

  const destroySDKSubscription = (item) => {
    const sdkSubscription = _get(item, 'data')
    sdkSubscription && sdkSubscription.destroy()
  }

  const destroySubscription = (subscriptionId) => {
    destroySDKSubscription(subscriptionGroup[subscriptionId])
    setSubscriptionGroup((prevSubscriptionGroup) => {
      prevSubscriptionGroup.splice(subscriptionId, 1)
      return [...prevSubscriptionGroup]
    })
  }

  const ChangeModeComponent = () => {
    const [selectedMode, setSelectedMode] = useState(0)
    const [changeModeResult, setChangeModeResult] = useState()
    const changeMode = () => {
      SDK.config.realtime.setAuthenticationConfig(
        !!selectedMode,
        3,
        (isConnected) => {
          setChangeModeResult(`Could enter authenticated mode: ${isConnected}`)
        }
      )
    }
    return (
      <Card
        title="Change Authentication mode"
        extra={
          <Button onClick={changeMode} disabled={false}>
            Make request
          </Button>
        }
      >
        <h2>Description</h2>
        <p>
          Changes the mode the realtime functionality to try to authenticate to
          the socket when sending and receiving messages. If this is changed
          while having active subscriptions. It will resubscribe with the
          desired values.
        </p>
        <h2>Request Parameters</h2>
        <Row gutter={4}>
          <Col xs={24}>
            <Select
              placeholder="Select a mode"
              optionFilterProp="children"
              onChange={(value) => {
                setSelectedMode(value)
              }}
              style={{ width: '100%' }}
            >
              <Option value={0}>Public</Option>
              <Option value={1}>Private</Option>
            </Select>
          </Col>
        </Row>
        {changeModeResult && (
          <div header="Response" key="1">
            <h2>Switch to private response:</h2>
            <pre>{changeModeResult}</pre>
          </div>
        )}
      </Card>
    )
  }

  return (
    <div>
      <h2>Real time configuration</h2>
      <RealTimeConfig />
      <ChangeModeComponent />
      <h2>
        Suscription groups{' '}
        <Button onClick={createNewSubscriptionGroup}>Create New</Button>
      </h2>
      {subscriptionGroup.map((sub, index) => (
        <SubscriptionCard
          key={index}
          groupId={index}
          createSubscription={createSubscription}
          destroySubscription={destroySubscription}
          subscription={sub.data}
          name={sub.name}
        />
      ))}
    </div>
  )
}

export default RealtimeTest
