import { connect } from 'react-redux'

import Search from '../../components/searchPage'

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

const mapStateToProps = (store) => ({
  user: store.user || {},
  featureFlags: store.config.featureFlags,
  urlListFooter: store.config.uiUrls && store.config.uiUrls.footer,
  urlListHeader: store.config.uiUrls && store.config.uiUrls.header,
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
