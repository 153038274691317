import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames/bind'
import { Spin, Icon } from 'antd'

import MSG from '../../constants/messages'

import styles from './styles.scss'

const cx = classNames.bind(styles)

class ProcessingOverlay extends Component {
  static defaultProps = {
    elmId: 'processing-overlay',
    message: MSG.LOADING,
  }

  static propTypes = {
    elmId: PropTypes.string,
    message: PropTypes.string,
  }

  render() {
    const { elmId, message } = this.props
    return (
      <div data-elm-id={elmId} className={cx('overlay')}>
        <div className={cx('overlay-inner')}>
          <Spin
            className={cx('spinner')}
            indicator={<Icon type="sync" spin />}
            size="large"
          />
          <p data-elm-id={`${elmId}-message`}>{message}</p>
        </div>
      </div>
    )
  }
}

export default ProcessingOverlay
