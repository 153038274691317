/* istanbul ignore file */
import React, { useEffect } from 'react'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import configment from '@ten-x/configment'
import styled from '@emotion/styled'
import Sdk from '@ten-x/fe-lib-sdk'

import restCalls from './restCalls'
import SDKRequestComponent from './sdkRequestComponent'
import RealTimeTest from './realtimeTest'

function SDKTest({ user }) {
  const back = encodeURIComponent('/test-sdk')
  const loginUrl =
    configment.get('LIB_AUTH_PATH_PREFIX') + `/auth/login?back=${back}`

  const logoutUrl = configment.get('LIB_AUTH_PATH_PREFIX') + '/auth/logout'

  useEffect(() => {
    Sdk.config.setBaseURL('/sdkProxy')
  }, [])

  return (
    <TestSDKContainer>
      <UserInfo>
        {(user && user.email && (
          <div>
            {' '}
            Current User : {user.email} -{' '}
            {/* logoutUrl does not have a backTo option, so this link will log out on a different tab to avoid losing the current page */}
            <a href={logoutUrl} target="_blank" rel="noreferrer">
              Logout
            </a>
          </div>
        )) || (
          <div>
            Not logged in: <a href={loginUrl}>Login</a>
          </div>
        )}
      </UserInfo>
      <h2>Realtime API</h2>
      <RealTimeTest />
      <h2>Listing API</h2>
      {restCalls.listingAPI.map((call, key) => (
        <SDKRequestComponent
          key={key}
          title={call.title}
          sdkFunction={call.sdkFunction}
          params={call.params}
          paramParsers={call.paramParsers}
          description={call.description}
        />
      ))}
      <h2>Bidder API</h2>
      {restCalls.bidderAPI.map((call, key) => (
        <SDKRequestComponent
          key={key}
          title={call.title}
          sdkFunction={call.sdkFunction}
          params={call.params}
          paramParsers={call.paramParsers}
          description={call.description}
        />
      ))}
      <h2>Registration API</h2>
      {restCalls.registrationAPI.map((call, key) => (
        <SDKRequestComponent
          key={key}
          title={call.title}
          sdkFunction={call.sdkFunction}
          params={call.params}
          paramParsers={call.paramParsers}
          description={call.description}
        />
      ))}
      <h2>Auth API</h2>
      {restCalls.authAPI.map((call, key) => (
        <SDKRequestComponent
          key={key}
          title={call.title}
          sdkFunction={call.sdkFunction}
          params={call.params}
          paramParsers={call.paramParsers}
          description={call.description}
        />
      ))}

      <h2>Config API</h2>
      {restCalls.configAPI.map((call, key) => (
        <SDKRequestComponent
          key={key}
          title={call.title}
          sdkFunction={call.sdkFunction}
          params={call.params}
          paramParsers={call.paramParsers}
          description={call.description}
        />
      ))}
    </TestSDKContainer>
  )
}
SDKTest.propTypes = {
  user: PropTypes.object,
}

const TestSDKContainer = styled.div`
  padding: 20px;

  pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
  }

  .ant-card {
    width: 100%;
    margin-bottom: 25px;
  }

  .ant-input-number {
    width: 100%;
  }
`

const UserInfo = styled.div`
  text-align: right;
  margin: 20px;
`

function mapStateToProps(store) {
  return {
    user: _get(store, 'user', {}),
  }
}

export default connect(mapStateToProps)(SDKTest)
