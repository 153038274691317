import fetch from '@ten-x/core-utils/src/helpers/fetch'
import configment from '@ten-x/configment'

export const setBidderBrokerRegistration = (brokerRegistrationId) => {
  const url = `${configment.get(
    'CRE_MS_BIDDER_REGISTRATION'
  )}/cre/bidder-registration/v1/bidders/broker/${brokerRegistrationId}`
  return fetch(url, {
    method: 'PUT',
  })
}
