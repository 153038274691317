import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'

const mapStateToProps = (store) => ({
  user: store.user || {},
})

const mapDispatchToProps = {}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PrivateRoute)
