import React, { useState, useRef } from 'react'
import { Row, Col, Carousel } from 'antd'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  margin: 100px 10%;
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  &:first-of-type {
    margin-top: 30px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    margin: 60px 1%;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: column;
    margin: 50px 2%;
  }
`

const TopRedLine = styled.div`
  height: 10px;
  background-color: #d32f2f;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const Content = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Header = styled.h2`
  font-size: 1.1rem;
  font-weight: bold;
  text-align: left;
  margin-left: 46px;
  margin-top: 20px;
  font-size: 2rem;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    text-align: center;
    margin-left: 0px;
    margin-top: 25px;
    font-size: 1.1rem;
  }
`

const Description = styled.p`
  font-size: 1.3rem;
  margin: 10px 45px 0px;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin: 0;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    font-size: 0.8rem;
  }
`

const SubHeader = styled.div`
  color: #d32f2f;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.5;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    margin: 10px 10% 0px;
    font-size: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin: 10px 0px 0px;
    font-size: 1.5rem;
  }
`

const MileStoneWrapper = styled.div<{ reverse: boolean }>`
  flex-direction: column;
  justify-content: right;
  flex-direction: column;
  display: flex;
  padding: 0px 20px;
  text-align: ${({ reverse }) => (reverse ? 'left' : 'right')};
  border-${({ reverse }) => (reverse ? 'left' : 'right')}: 1px solid #d32f2f;
  height: 100px;
  margin: 20px 43px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    text-align: center;
    border-top: 1px solid #d32f2f;
    border-right: none;
    border-left: none;
    margin: 10px 0px 0px;
  }

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    margin: 10px 10% 0px;
  }

      @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin: 10px 0 0px;
  }


`

const Dots = styled.div<{ activeIndex: number; reverse: boolean }>`
  display: flex;
  justify-content: ${({ reverse }) => (reverse ? 'left' : 'right')};
  margin: 0 80px;

  span {
    font-size: 18px;
    color: #ccc;
    margin: 0 5px;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    cursor: pointer;
  }

  span.active {
    color: #d32f2f;
    transform: scale(1.2);
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    justify-content: center;
    margin: 0px 10px 20px;
  }
`

const MileStone = styled.div`
  font-size: 1.2rem;
`

const ImageContainer = styled.div`
  position: relative;
  min-height: 300px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    min-height: 0px;
  }
`

const StyledCol = styled(Col)<{ reverse: boolean }>`
  order: ${({ reverse }) => (reverse ? 2 : 1)};

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    order: 1;
    width: 100%;
  }
`

const AdvertisementItem = React.memo(
  ({
    images,
    header,
    subHeader,
    description,
    reverse = false,
    milestone,
    active,
  }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const carouselRef = useRef<any>(null)

    const handleDotClick = (index: number) => {
      setActiveIndex(index)
      carouselRef.current.goTo(index)
    }

    return (
      <Container>
        <TopRedLine />
        <StyledCol span={12} reverse={reverse}>
          <ImageContainer>
            <Carousel
              autoplay
              dots={false}
              beforeChange={(current, next) => setActiveIndex(next)}
              ref={carouselRef}
            >
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={header}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ))}
            </Carousel>
          </ImageContainer>
        </StyledCol>
        <StyledCol span={12} reverse={!reverse}>
          <Content>
            <Header>{header}</Header>
            <Description>{description}</Description>
            <MileStoneWrapper reverse={reverse}>
              <SubHeader>{subHeader}</SubHeader>
              <MileStone>{milestone}</MileStone>
            </MileStoneWrapper>
            <Dots activeIndex={activeIndex} reverse={reverse}>
              {images.map((_, index) => (
                <span
                  key={index}
                  className={index === activeIndex ? 'active' : ''}
                  onClick={() => handleDotClick(index)}
                >
                  •
                </span>
              ))}
            </Dots>
          </Content>
        </StyledCol>
      </Container>
    )
  }
)

export const AdvertisementBox = () => {
  const advertisements = [
    {
      images: [
        '/images/homepage/SPEED-1_R2.jpg',
        '/images/homepage/SPEED-2_R2.jpg',
        '/images/homepage/SPEED-3_R2.jpg',
      ],
      header: 'SPEED',
      subHeader: '97 Days',
      description:
        'Our condensed process accelerates the sales transaction to under 100 days, getting our clients from list to close faster.',
      milestone: 'Avg List to Close',
      reverse: false,
      active: true,
    },
    {
      images: [
        '/images/homepage/REACH-1_R2.jpg',
        '/images/homepage/REACH-2_R2.jpg',
        '/images/homepage/REACH-3_R2.jpg',
      ],
      header: 'REACH',
      subHeader: '13 Million',
      description:
        'We market your listing to the largest qualified global buyer pool, using comprehensive multi-channel marketing to help put your property in front of the perfect buyer.',
      milestone: 'Unique Monthly Vistors',
      reverse: true,
      active: false,
    },
    {
      images: [
        '/images/homepage/CERTAINTY-1_R2.jpg',
        '/images/homepage/CERTAINTY-2_R2.jpg',
        '/images/homepage/CERTAINTY-3_R2.jpg',
      ],
      header: 'CERTAINTY',
      subHeader: '95%',
      description:
        'We implement upfront bidder qualification and due diligence, reducing the risk of fallouts and retrades so you can close with confidence.',
      milestone: 'Close Rate',
      reverse: false,
      active: false,
    },
  ]

  return (
    <div>
      {advertisements.map((ad, index) => (
        <AdvertisementItem
          key={index}
          images={ad.images}
          header={ad.header}
          subHeader={ad.subHeader}
          description={ad.description}
          milestone={ad.milestone}
          reverse={ad.reverse}
          active={ad.active}
        />
      ))}
    </div>
  )
}
