import React from 'react'
import styled from '@emotion/styled'
import { Row, Col } from 'antd'

interface IStat {
  value: string
  label: string
}

interface IDescription {
  title: string
  content: string
}

interface IProps {
  stats: IStat[]
  description: IDescription
}

export const Statistics: React.FC<IProps> = ({ stats, description }) => {
  return (
    <Container>
      <StatRow gutter={24}>
        <StatCol1>
          {stats.slice(0, 2).map((stat, index) => (
            <ItemWrapper key={index}>
              <StatItem>
                <StatValue>{stat.value}</StatValue>
                <StatLabel>{stat.label}</StatLabel>
              </StatItem>
            </ItemWrapper>
          ))}
        </StatCol1>

        <StatCol2>
          {stats.slice(2, 4).map((stat, index) => (
            <ItemWrapper key={index + 2}>
              <StatItem>
                <StatValue>{stat.value}</StatValue>
                <StatLabel>{stat.label}</StatLabel>
              </StatItem>
            </ItemWrapper>
          ))}
        </StatCol2>

        <Description>
          <Title>{description.title}</Title>
          <p>{description.content}</p>
        </Description>
      </StatRow>
    </Container>
  )
}

const StatRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
`

const StatCol1 = styled.div`
  flex: 1;
`

const StatCol2 = styled.div`
  flex: 1;
`

const Description = styled.div`
  flex: 1;
  padding-left: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    text-align: center;
    flex: none;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding-left: 0px;
  }
`

const StatItem = styled.div`
  text-align: right;
  padding-right: 30px;
  border-right: 1px solid #d32f2f;
  border-bottom-width: 80px;
  line-height: 0.9;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding-right: 10px;
  }
`

const StatValue = styled.div`
  color: #d32f2f;
  font-size: 2.2rem;
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 1.7rem;
  }
`

const StatLabel = styled.div`
  font-size: 1.5rem;
  margin-top: 10px;
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 1rem;
  }
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 90px 20% 90px;
  border-radius: 8px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    margin: 90px 10% 90px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin: 60px 10% 60px;
  }
`

const ItemWrapper = styled(Col)`
  padding: 20px 0px;
  min-height: 130px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding: 20px 7px;
  }
`

const Title = styled.h3`
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-bottom: 0.5rem;
  }
`
