import PropTypes from 'prop-types'

export default {
  HERO_TITLE: PropTypes.string,
  HERO_SUBTITLE: PropTypes.string,
  HERO_PARAGRAPH: PropTypes.string,
  HERO_IMAGE: PropTypes.string,
  HERO_CUSTOMER_STATS: PropTypes.string,
  SECTION_2_TITLE: PropTypes.string,
  SECTION_2_PARAGRAPH: PropTypes.string,
  SECTION_2_IMAGE: PropTypes.string,
  SECTION_2_CUSTOMER_LOGOS_1: PropTypes.string,
  SECTION_2_CUSTOMER_LOGOS_2: PropTypes.string,
  SECTION_2_CUSTOMER_LOGOS_DESKTOP: PropTypes.string,
  SECTION_2_STRATEGY_IMAGE_MOBILE: PropTypes.string,
  SECTION_2_STRATEGY_IMAGE: PropTypes.string,
  SECTION_3_TITLE: PropTypes.string,
  SECTION_3_PARAGRAPH: PropTypes.string,
  SECTION_3_IMAGE: PropTypes.string,
  SECTION_3_ICON_SECTION: PropTypes.arrayOf(
    PropTypes.shape({
      ICON_SRC: PropTypes.string,
      ICON_TITLE: PropTypes.string,
      ICON_DESCRIPTION: PropTypes.string,
    })
  ),
  SECTION_4_TITLE: PropTypes.string,
  SECTION_4_PARAGRAPH: PropTypes.string,
  SECTION_4_IMAGE: PropTypes.string,
  PARDOT_DESCRIPTION: PropTypes.string,
}
