import React from 'react'
import styled from '@emotion/styled'

import milestoneLogo from './milestone.png' // Import the logo image

const MilestoneChart: React.FC = () => {
  return (
    <Container>
      <Title>FROM LIST TO CLOSE IN UNDER 100 DAYS</Title>
      <Timeline>
        {milestones.map((milestone, index) => (
          <Milestone key={index}>
            <MilestoneHeader>
              <MilestoneTitle>{milestone.title}</MilestoneTitle>
              <MilestoneDuration>{milestone.duration}</MilestoneDuration>
            </MilestoneHeader>
            <MilestoneLogo src={milestoneLogo} alt="Milestone Logo" />
            <MilestoneDescription>{milestone.description}</MilestoneDescription>
          </Milestone>
        ))}
      </Timeline>
    </Container>
  )
}

const milestones = [
  {
    title: 'Asset Strategy',
    duration: 'Start',
    description:
      'Each asset receives a thorough, upfront evaluation that leverages CoStar data and market conditions, working with the broker and seller to establish a reserve price.',
  },
  {
    title: 'Onboarding',
    duration: '10 Days',
    description:
      'We collect all due diligence documentation during the onboarding process so we can provide this information to investors upfront, reducing the risk of contingencies and surprises and resulting in greater certainty.',
  },
  {
    title: 'Asset Marketing',
    duration: '45 Days',
    description:
      'Immediately upon listing, each property receives a customized marketing plan deploying digital marketing and personalization to reach the right audience.',
  },
  {
    title: 'Online Auction',
    duration: '2 Days',
    description:
      'Once the auction begins, bidders have a 2-day window to submit their bids online, while brokers and owners can monitor the results in real time.',
  },
  {
    title: 'Contracts & Closing',
    duration: '30-45 Days',
    description:
      'With an approach that includes no contingencies, pre-qualified buyers, a set timeframe, and upfront due diligence, we minimize uncertainties and accelerate the transaction for an average closing period of 30-45 days.',
  },
]

const Container = styled.div`
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top: 16px solid;
  border-image: linear-gradient(to right, #0054a6, #cf0000) 1;
  margin: 30px;
  min-width: 1380px;
`

const Title = styled.h2`
  text-align: center;
  color: #cf0000;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
`

const Timeline = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 31%;
    left: 0;
    right: 0;
    height: 6px;
    background-color: #dcdcdc;
    z-index: 0;
    margin-right: 130px;
    margin-left: 150px;
    min-width: 1080px;
  }
`

const Milestone = styled.div`
  text-align: center;
  min-width: 270px;
  max-width: 275px;
  position: relative;
  z-index: 1;
`

const MilestoneHeader = styled.div`
  margin-bottom: 90px;
`

const MilestoneTitle = styled.h3`
  font-size: 16px;
  margin: 0;
  padding-bottom: 10px;
  text-transform: uppercase;
`

const MilestoneDuration = styled.p`
  font-size: 14px;
  color: #000;
`

const MilestoneLogo = styled.img`
  margin-bottom: 10px;
  z-index: 1;
  height: 45px;
  position: absolute;
  left: 50%;
  transform: translate(-60%, -160%) scale(0.5);
  transition: transform 0.5s ease-in-out;
  opacity: 0;
  animation: bounceIn 1s forwards;

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: translate(-60%, -160%) scale(0.5);
    }
    50% {
      opacity: 1;
      transform: translate(-60%, -160%) scale(1.1);
    }
    70% {
      transform: translate(-60%, -160%) scale(0.9);
    }
    100% {
      opacity: 1;
      transform: translate(-60%, -160%) scale(1);
    }
  }
`

const Divider = styled.div`
  height: 6px;
  background-color: #c00;
  margin: 10px 5px;
`

const MilestoneDescription = styled.p`
  font-size: 12px;
  color: #333;
  padding-top: 20px;
  opacity: 0;
  animation: fadeIn 3s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`

export default MilestoneChart
