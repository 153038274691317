/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import configment from '@ten-x/configment'
import slugify from 'slugify'

import ArticleItem from '../articleItem'
import WPProvider from '../../data_providers/drupal'

import EducationCenterImage from './education-help-center.jpg'

const RecentNewsArticles = ({
  title = '',
  posts = [],
  category,
  showEducationImage = false,
  showEducationVideos = false,
}) => {
  const [newsArticlesArray, setNewsArticlesArray] = useState([])
  const [educationVideos, setEducationVideos] = useState([])
  const [categories, setCategories] = useState([])
  const DRUPAL_ENDPOINT = configment.get('DRUPAL_BASE_URL')

  useEffect(() => {
    setNewsArticlesArray(posts)

    const getArticleCategories = async () => {
      const categories = await WPProvider.getArticleCategories()
      setCategories(categories)
    }

    const getArticleEducationVideos = async () => {
      setEducationVideos(await WPProvider.getArticleEducationVideos())
    }

    if (!category) {
      getArticleCategories()
    }

    if (showEducationVideos) {
      getArticleEducationVideos()
    }
  }, [posts])

  const getPostCategory = (post) => {
    const categoryId =
      post?.field_article_category?.data?.id || post?.field_article_category?.id
    const matchingCategory = categories?.find(({ id }) => id === categoryId)
    if (matchingCategory) {
      return matchingCategory?.attributes?.field_slug
    }
  }

  return (
    <>
      {educationVideos?.length > 0 && (
        <>
          <TitleContainer>
            <h2>HOW TO VIDEOS</h2>
            <Divider />
          </TitleContainer>
          <EducationVideosContainer>
            {educationVideos.map((video) => {
              const extraAttributes = {}
              let slug = video?.field_article_slug
              if (!slug) {
                slug = `${slugify(video?.title, {
                  lower: true,
                })}/${video?.id}`
              }
              const videoUrl = `${category}/${slug}`
              const featuredImage = video?.images?.url
              const videoThumbnail = `${DRUPAL_ENDPOINT}${featuredImage}`
              const videoTitle = video?.title

              return (
                <VideoContainer
                  href={videoUrl}
                  key={videoUrl}
                  thumbnail={videoThumbnail}
                  {...extraAttributes}
                >
                  <h3>{videoTitle}</h3>
                </VideoContainer>
              )
            })}
          </EducationVideosContainer>
        </>
      )}
      <TitleContainer>
        {title ? <h2>{title}</h2> : null}
        <Divider />
      </TitleContainer>
      {showEducationImage ? (
        <a href="/company/commercial/help/">
          <ArticleImage helpCenter imageSrc={EducationCenterImage}>
            <h3>Help Center</h3>
          </ArticleImage>
        </a>
      ) : null}
      {newsArticlesArray?.length > 0 &&
        newsArticlesArray.map((post) => (
          <ArticleItem
            key={post.id}
            post={post}
            category={category || getPostCategory(post)}
          />
        ))}
    </>
  )
}

const Divider = styled.hr`
  border-bottom: 1px solid #595959;
  flex-grow: 1;
`

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  h1,
  h2,
  h3 {
    color: #595959;
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 15px;
    @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      font-size: 20px;
    }
  }
  padding-top: 25px;

  span {
    display: none;
    @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      display: inline;
    }
  }
`

const ArticleImage = styled.div`
  align-items: center;
  background-image: url('${(props) => props.imageSrc}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 160px;
  min-width: 35%;
  width: 100%;
  h3 {
    color: white;
    font-weight: 400;
    font-size: 22px;
    margin-left: 20px;
    margin-bottom: 0;

    @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      font-size: 40px;
      margin-left: 60px;
    }

    @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      font-size: 60px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    min-height: ${(props) => (props.helpCenter ? '300px' : 'unset')};
    max-height: 300px;
    max-width: ${(props) => (props.helpCenter ? 'unset' : '30%')};
  }
`

const EducationVideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  padding-top: 10px;
`

const VideoContainer = styled.a`
  background-image: url('${({ thumbnail }) => thumbnail}');
  background-size: cover;
  background-position: center;
  height: 0;
  max-height: 300px;
  padding-top: 50%;
  position: relative;
  width: calc(50% - 10px);

  h3,
  h4 {
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.266667) 61.98%,
      rgba(0, 0, 0, 0.8) 100%
    );
    color: white;
    display: flex;
    font-weight: 400;
    height: 100%;
    justify-content: center;
    margin-bottom: 0;
    padding: 10px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  iframe {
    height: 100%;
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding-top: 25%;
    width: calc(33% - 20px);

    h3,
    h4 {
      font-size: 20px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    gap: 30px;
  }
`

const PostObject = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      rendered: PropTypes.string,
    }),
  ]),
  content: PropTypes.shape({
    rendered: PropTypes.string,
  }),
  excerpt: PropTypes.shape({
    rendered: PropTypes.string,
  }),
  sticky: PropTypes.bool,
}

RecentNewsArticles.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.shape(PostObject)),
  category: PropTypes.string,
  showEducationImage: PropTypes.bool,
  showEducationVideos: PropTypes.bool,
}

export default RecentNewsArticles
