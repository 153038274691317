export const LABELS = {
  NEWS: 'In The News',
  EDUCATION: 'How To',
  INSIGHTS: 'Insights',
  SUCCESS_STORIES: 'Success Stories',
}

export const CATEGORY_NAMES = {
  NEWS: 'News',
  EDUCATION: 'Education',
  INSIGHTS: 'Insights',
  SUCCESS_STORIES: 'Success Stories',
}

export const CATEGORY_TYPE = {
  NEWS: 'news',
  EDUCATION: 'education',
  INSIGHTS: 'insights',
  SUCCESS_STORIES: 'success-stories',
}

export const HEADING_TYPES = {
  HERO_HEADING: 'HERO_HEADING',
  HERO_SUBTITLE: 'HERO_SUBTITLE',
  SECTION_2_TITLE: 'SECTION_2_TITLE',
}
