import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import sanitizeHtml from 'sanitize-html'

import HelpContent from './helpContent'

const HelpTopic = ({ topic, contents }) => {
  const pureContent = sanitizeHtml(topic?.attributes?.body?.processed)

  return (
    <>
      {topic ? (
        <TopicArticleContainer>
          <ArticleContentContainer>
            <ArticleContent
              dangerouslySetInnerHTML={{
                __html: pureContent,
              }}
            />
            {topic?.relationships?.field_help_contents?.data?.length > 0 &&
              topic.relationships.field_help_contents.data.map(
                (helpContent) => (
                  <HelpContent
                    key={helpContent.id}
                    content={contents[helpContent.id]}
                  />
                )
              )}
          </ArticleContentContainer>
        </TopicArticleContainer>
      ) : null}
    </>
  )
}

const TopicArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  font-size: 16px;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: row;
    margin: 0;
  }
`

const ArticleContentContainer = styled.div`
  color: rgba(136, 136, 136, 1);
  font-weight: 300;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const ArticleContent = styled.div``

const MemberObject = {
  title: PropTypes.string,
}

HelpTopic.propTypes = {
  topic: PropTypes.shape(MemberObject),
  contents: PropTypes.shape(MemberObject),
}

export default HelpTopic
