import React from 'react'
import { Breadcrumb } from 'antd'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import configment from '@ten-x/configment'

import { capitalize } from '../../utils/format'

const BreadcrumbsNav = ({ showAsH1 = false, urlList = [] }) => {
  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')
  return (
    <BreadcrumbsContainer>
      <Breadcrumb.Item href={KNOWLEDGE_CENTER_URL}>Home</Breadcrumb.Item>
      {urlList.length > 0 &&
        urlList.map((item, idx, { length }) => (
          <Breadcrumb.Item key={item.href} href={item.href}>
            {showAsH1 && length - 1 === idx ? (
              <h1>{capitalize(item.label)}</h1>
            ) : (
              <span>{capitalize(item.label)}</span>
            )}
          </Breadcrumb.Item>
        ))}
    </BreadcrumbsContainer>
  )
}

const BreadcrumbsContainer = styled(Breadcrumb)`
  margin-bottom: 10px;
  text-align: left;

  h1 {
    display: inline;
    font-size: 14px;
    font-weight: 300;
  }
`

BreadcrumbsNav.propTypes = {
  showAsH1: PropTypes.bool,
  urlList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
    })
  ),
}

export default BreadcrumbsNav
