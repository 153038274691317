export default {
  TITLE: 'List Commercial Real Estate Online and Close Within 100 Days',
  DESCRIPTION:
    'See why over 7,000 brokers trust Ten-X to accelerate their online commercial real estate transactions. Get greater reach, transparency, control, and speed at no cost. Partner with us for an efficient online CRE auction from list to close.',
  CANONICAL: 'https://www.ten-x.com/broker-commercial-real-estate',
  HERO_TITLE: 'BROKERS',
  HERO_SUBTITLE: 'ACCELERATE YOUR TRANSACTION.',
  HERO_HEADING: 'THE #1 DIGITAL CRE AUCTION PLATFORM',
  HERO_PARAGRAPH:
    'The integration of Ten-X, LoopNet, and CoStar gives you 12x more reach, unparalleled transparency, greater control, and unmatched speed. It’s now easier than ever to transact CRE online with no cost to sellers or brokers.',
  HERO_IMAGE: '/uploads/BROKER_HERO_V3.png',
  HERO_IMAGE_MOBILE: '/uploads/BROKER_HERO_MOBILE.png',
  HERO_CUSTOMER_STATS: '/uploads/BROKER_STATS_V3-1.png',
  SECTION_2_TITLE: 'A PARTNERSHIP FOR SUCCESS',
  SECTION_2_PARAGRAPH:
    'Ten-X’s partnership with brokers is critical to the success of the auction. Our team works with you and your client from list to close, ensuring you meet your goals, make informed decisions, and are prepared to adapt to changes in the market. The results? More speed, more certainty.',
  SECTION_2_IMAGE: '/uploads/BROKER_EXPERT.png',
  SECTION_2_CUSTOMER_LOGOS_1: '/uploads/BROKER_IMAGES_SET_1.png',
  SECTION_2_CUSTOMER_LOGOS_2: '/uploads/BROKER_IMAGES_SET_2.png',
  SECTION_2_CUSTOMER_LOGOS_DESKTOP: '/uploads/BROKER_IMAGES_DESKTOP.png',
  SECTION_2_STRATEGY_IMAGE_MOBILE: '/uploads/BROKER_SELLER_STRATEGY_MOBILE.png',
  SECTION_2_STRATEGY_IMAGE: '/uploads/BROKER_SELLER_STRATEGY.png',
  SECTION_3_TITLE: 'DRIVE DEMAND WITH SUPERCHARGED MARKETING',
  SECTION_3_PARAGRAPH:
    'Ten-X provides you with a turnkey marketing program that guarantees maximum exposure of your property. We deploy best-in-class digital marketing, AI targeting, and personalization to reach the right audiences, at the right place, at the right time.',
  SECTION_3_IMAGE: '/uploads/BROKER_LAPTOP.png',
  SECTION_3_ICON_SECTION: [
    {
      ICON_SRC: '/uploads/MATTERPORT_ICON.png',
      ICON_TITLE: 'Matterport 3D Imaging',
      ICON_DESCRIPTION:
        'Engage prospects with an immersive 3D tour experience that increases time spent on your property’s listing page.',
    },
    {
      ICON_SRC: '/uploads/CAMERA_ICON.png',
      ICON_TITLE: 'Professional Photography',
      ICON_DESCRIPTION:
        "Capture prospects' attention with high-quality, professional photography from our Architectural Photographers.",
    },
    {
      ICON_SRC: '/uploads/VIDEO_ICON.png',
      ICON_TITLE: 'Drone Videography',
      ICON_DESCRIPTION:
        'Showcase a new perspective of your property and its proximity to demand drivers with video fly-throughs and aerial footage.',
    },
    {
      ICON_SRC: '/uploads/PAPER_ICON.png',
      ICON_TITLE: 'SEO Optimization',
      ICON_DESCRIPTION:
        'Reach a larger audience of qualified buyers with page content designed to bring greater visibility and traffic to your listing.',
    },
  ],
  SECTION_4_TITLE: 'MAXIMIZE YOUR RESULTS AT NO COST TO YOU.',
  SECTION_4_PARAGRAPH:
    'Ten-X’s partnership with brokers is critical to the success of the auction. By removing the financial barriers to listing, we’re not just offering a service; we’re investing in a partnership that grows your business and, in turn, enhances our marketplace.',
  SECTION_4_IMAGE: '/uploads/BROKER_CUSTOMER.png',
  PARDOT_DESCRIPTION:
    'Learn more about how Ten-X can help you close more transactions faster and with certainty.',
}
