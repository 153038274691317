// Define the App static messages by subject so they can be easily found.
const _others = {
  LOADING: 'Loading..',
  TENX_PHONE_NUMBER: 8887707332,
  TENX_FORMATTED_PHONE_NUMBER: '(888) 770-7332',
  EMAIL_ADDRESS: 'Email Address',
  EMAIL_INVALID: 'Please enter a valid email.',
  HELP_MSG: 'Need help?',
  SUBMIT: 'SUBMIT',
  AND: 'and',
  OR: 'or',
  HELMET_TITLE_TEMPLATE: 'Ten-X Commercial Real Estate',
  HELMET_META_DESCRIPTION:
    "Ten-X Commercial is the nation's leading commercial real estate marketplace. Our platform enables investors and brokers to buy and sell properties online.",
  WELCOME_TO_TENX: 'Welcome to Ten-X!',
  QUESTIONS_Q: 'Questions?',
  DO_YOU_WANT_TO: 'Do you want to',
  HERE: 'here',
  CALL_US: 'Call us at',
  CALL_HOURS: 'Mon-Fri 10am-8pm (Eastern Time).',
}
// Save asset
const _saveAsset = {
  ASSET_SAVED_TITLE: 'Save Successful',
  ASSET_SAVED:
    'The listing has been saved. Access all your saved listings in My Profile under Saved Listings',
  ASSET_SAVE_FAILED_TITLE: 'Save Failed',
  ASSET_SAVE_FAILED:
    'There is a problem saving the property. Please try again later.',
  ASSET_REMOVED_TITLE: 'Property Removed',
  ASSET_REMOVED: 'The property has been removed.',
  ASSET_REMOVE_FAILED_TITLE: 'Remove Property Failed',
  ASSET_REMOVE_FAILED:
    'There is a problem removing the property. Please try again later.',
}

const _login = {
  LOG_IN_CAP: 'LOG IN',
  LOG_IN_LOW: 'log in',
  LOG_IN: 'Log in',
  SIGN_UP: 'SIGN UP',
  BUY_SELL_PROPERTIES: 'Buy and sell properties',
  MANAGE_LISTINGS: 'Manage your listings',
  GET_PROPERTY_ALERTS: 'Get custom property alerts',
}

const _activate = {
  ACTIVATE: 'Activate Your Account',
  INVITE_CODE_INVALID_TITLE: 'Invitation Invalid',
  INVITE_CODE_INVALID_BODY:
    'This invitation code has already been used or is incorrect',
  ACTIVATION_COMPLETED: 'Your account has been activated successfully',
  GOTO_DASHBOARD_TEXT: 'here',
  WELCOME_EMAIL_SENT: "We've sent a welcome email to",
  ACTIVATE_LOGIN: 'Login to start browsing',
  ACTIVATE_CALL_US_IF_NO_WELCOME_EMAIL:
    "If you didn't receive our welcome email or are having trouble logging in, call us at",
}

const _signup = {
  SIGN_UP_CAP: 'SIGN UP',
  SIGN_UP: 'Sign Up',
}

const _resendConfirmEmail = {
  RESEND_CONFIRM_EMAIL: 'Resend Confirmation Email',
  CONFIRMATION_RESENT: 'Your Confirmation was Resent',
  CHECK_EMAIL_TO_CONTINUE: 'Check your email to continue.',
  NO_ACCOUNT_ASSOCIATED_WITH_THAT_EMAIL:
    'There is no account associated with that email address.',
  RESEND_CONFIRM_EMAIL_ERROR_TITLE: 'Error resending confirmation email',
  RESEND_CONFIRM_EMAIL_ERROR_BODY:
    'There was an error resending your confirmation email, please try again',
  RESEND_CONFIRM_EMAIL_ERROR_LOGIN: 'Go to login',
}

const _confirmUser = {
  ACCOUNT_CONFIRMATION: 'Account Confirmation',
  CONFIRM_ACCOUNT_CONGRAT: 'Congratulations! Your account has been confirmed.',
  CONFIRM_USER_ERROR_TITLE: 'Error confirming user account',
  CONFIRM_USER_ERROR_BODY:
    'There was an error confirming your account, please try again',
  ACCOUNT_ALREADY_CONFIRMED_TITLE: 'Your account has already been confirmed',
  ACCOUNT_ALREADY_CONFIRMED_BODY:
    'There is already an account associated with this email address.',
  RECOVER_PASSWORD_Q: 'recover your password?',
}

const _resetEmail = {
  RESET_EMAIL: 'Reset Email',
  RESET_EMAIL_ERROR_TITLE: 'Error resetting your email',
  RESET_EMAIL_ERROR_BODY: 'Please try again...',
  RESET_EMAIL_SUCCESS_LINE1:
    'Congratulations! You have successfully changed your email address.',
  RESET_EMAIL_SUCCESS_LINE2:
    'Please login below to continue using your account.',
  RESET_EMAIL_INVALID_REQUEST: 'Invalid Request',
  RESET_EMAIL_TOKEN_EXPIRED: 'Reset email token has expired',
  RESET_EMAIL_ALREADY_CHANGED:
    "You've already successfully changed your email address",
}

const _confirmBroker = {
  BROKER_CONFIRMED: 'Broker Representation Confirmed',
  BROKER_CONFIRMATION: 'Broker Confirmation',
  CONFIRM_BROKER_CONGRAT: 'Broker Representation Confirmed',
  CONFIRM_BROKER_ERROR: 'Unable to confirm the relationship with your broker',
}

// Use spread operator to include the values on the exports.
module.exports = {
  ..._others,
  ..._activate,
  ..._login,
  ..._signup,
  ..._resendConfirmEmail,
  ..._confirmUser,
  ..._saveAsset,
  ..._resetEmail,
  ..._confirmBroker,
}
