import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import configment from '@ten-x/configment'

import { LABELS } from '../../constants/enums'

import NewsImage from './news.png'
import InsightsImage from './insights.png'
import EducationImage from './education.png'
import SuccessStoriesImage from './success-stories.png'

const CATEGORIES = [
  {
    title: LABELS.EDUCATION,
    href: '/education',
    imageSrc: EducationImage,
  },
  {
    title: LABELS.INSIGHTS,
    href: '/insights',
    imageSrc: InsightsImage,
  },
  {
    title: LABELS.SUCCESS_STORIES,
    href: '/success-stories',
    imageSrc: SuccessStoriesImage,
  },
  { title: LABELS.NEWS, href: '/news', imageSrc: NewsImage },
]

const CategoryTiles = ({ currentCategoryName, disableOpaque = true }) => {
  return (
    <Container>
      {CATEGORIES.map((category, index) => (
        <Tile
          disableOpaque={disableOpaque}
          index={index}
          key={category.title}
          currentCategoryName={currentCategoryName}
          category={category}
        />
      ))}
    </Container>
  )
}

const Tile = ({ currentCategoryName, category, disableOpaque, index }) => {
  const KNOWLEDGE_CENTER_URL = configment.get('KNOWLEDGE_CENTER_URL')
  return (
    <TileContainer
      disableOpaque={disableOpaque}
      opaque={currentCategoryName !== category.title.toLowerCase()}
      even={index % 2 !== 0}
      href={`${KNOWLEDGE_CENTER_URL}${category.href}`}
    >
      <TileTitle
        disableOpaque={disableOpaque}
        opaque={currentCategoryName !== category.title.toLowerCase()}
      >
        {category.title}
      </TileTitle>
      <Image src={category.imageSrc} />
    </TileContainer>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-wrap: nowrap;
    a + a {
      margin-left: 10px;
    }
  }
`

const TileContainer = styled.a`
  height: auto;
  opacity: ${(props) => (!props.disableOpaque && props.opaque ? '0.3' : '1')};
  position: relative;
  transition: all 0.5s;
  width: calc(50% - 10px);

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-left: ${(props) => (props.even ? '10px' : '0px')};
  }

  :hover {
    opacity: 1;
  }
`

const TileTitle = styled.p`
  align-items: center;
  background: ${(props) =>
    !props.disableOpaque && props.opaque
      ? 'unset'
      : 'linear-gradient( 180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.266667) 61.98%, rgba(0, 0, 0, 0.8) 100%);'};
  color: white;
  display: flex;
  font-size: 16px;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;

  :hover {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.266667) 61.98%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`

const Image = styled.img`
  width: 100%;
`

Tile.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    imageSrc: PropTypes.string,
  }),
  currentCategoryName: PropTypes.string,
  disableOpaque: PropTypes.bool,
  index: PropTypes.number,
}

CategoryTiles.propTypes = {
  currentCategoryName: PropTypes.string,
  disableOpaque: PropTypes.bool,
}

export default CategoryTiles
