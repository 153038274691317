/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { styles } from '@ten-x/fe-lib-ui2020'

import analytics from '../../services/analytics'
import WPProvider from '../../data_providers/drupal'
import KNOWLEDGE_CENTER, { BANNERS } from '../../constants/knowledge_center'
import { Banner } from '../../components/sections/banner'

import RecentNewsArticles from './recentNewsArticles'
import CategoryTiles from './categoryTiles'
import FeaturedArticle from './featuredArticle'

const { withThemeProvider } = styles
export const LearnLayout = ({
  location,
  featureFlags,
  urlListHeader,
  urlListFooter,
  user,
}) => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    analytics.onPageLoadKnowledgeCenter()
    const getPosts = async () => {
      setPosts(await WPProvider.getRecentArticles())
      setLoading(false)
    }
    getPosts()
  }, [])

  return (
    <Container>
      <Helmet>
        <title>{KNOWLEDGE_CENTER.TITLE}</title>
        <meta name="description" content={KNOWLEDGE_CENTER.DESCRIPTION} />
        <link rel="canonical" href={KNOWLEDGE_CENTER.CANONICAL} />
      </Helmet>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Banner
            header={BANNERS.AUCTION_NEWS.TITLE}
            description={BANNERS.AUCTION_NEWS.DESCRIPTION}
          />
          <CategoryTiles />
          {posts?.featuredArticle?.length > 0 && (
            <FeaturedArticle
              post={posts.featuredArticle[0]}
              categories={posts.categories}
            />
          )}
          {posts?.education?.length > 0 && (
            <RecentNewsArticles
              title="HOW TO"
              posts={posts.education}
              category="education"
            />
          )}
          {posts?.insights?.length > 0 && (
            <RecentNewsArticles
              title="RECENT INSIGHTS"
              posts={posts.insights}
              category="insights"
            />
          )}
          {posts?.successStories?.length > 0 && (
            <RecentNewsArticles
              title="RECENT SUCCESS STORIES"
              posts={posts.successStories}
              category="success-stories"
            />
          )}
          {posts?.news?.length > 0 && (
            <RecentNewsArticles
              title="RECENT NEWS"
              posts={posts.news}
              category="news"
            />
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1100px;

  text-align: center;
  width: 100%;
  padding: 0 10px;
`

export default withThemeProvider(LearnLayout)

LearnLayout.propTypes = {
  location: PropTypes.object,
  featureFlags: PropTypes.object.isRequired,
  urlListFooter: PropTypes.object.isRequired,
  urlListHeader: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}
