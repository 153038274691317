import React from 'react'
import styled from '@emotion/styled'
import { utils } from '@ten-x/fe-lib-ui2020'

import FormImage from './contact-form-image.png'

const LeadForm = () => {
  const params = utils.createPardotUtmFragment()
  return (
    <>
      <Divider />
      <ContentContainer>
        <ContactFormImage imageSrc={FormImage} />
        <FormContainer>
          <Title>
            LEARN MORE ABOUT <br /> PARTNERING WITH TEN-X
          </Title>
          <Subtitle>YOU COULD CLOSE IN UNDER 100 DAYS</Subtitle>
          <Text>
            Use the power of Ten-X to close more sales. Call us at (888)
            770-7332 or fill out the form below to find out how we can help you
            go from list to close in under 100 days on Ten–X.
          </Text>
          <Form
            src={`https://go.ten-x.com/l/667613/2023-10-16/689sn${params}`}
            width="100%"
            frameBorder="0"
            style={{ border: 0 }}
            scrolling="no"
          />
        </FormContainer>
      </ContentContainer>
    </>
  )
}

const Divider = styled.hr`
  display: none;
  width: 75%;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: block;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 1440px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: row;
    margin-top: 22px;
  }
`

const ContactFormImage = styled.div`
  background-image: url('${(props) => props.imageSrc}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  display: none;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: block;
    flex: 1.5;
    margin-right: 20px;
  }
`

const FormContainer = styled.div`
  flex: 1;
`

const Title = styled.div`
  color: rgba(5, 84, 158, 1);
  border-top: 5px solid rgba(5, 84, 158, 1);
  border-bottom: 5px solid rgba(5, 84, 158, 1);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    border-top: 10px solid rgba(5, 84, 158, 1);
    border-bottom: 10px solid rgba(5, 84, 158, 1);
    font-size: 30px;
  }
`

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 18px;
  }
`

const Text = styled.div`
  font-size: 10px;
  margin: 15px 0;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 12px;
  }
`

const Form = styled.iframe`
  height: 500px;
`

export default LeadForm
