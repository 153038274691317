import React from 'react'
import { styles } from '@ten-x/fe-lib-ui2020'
import { Row, Col } from 'antd'
import styled from '@emotion/styled'

import { Banner } from '../sections/banner'
import analytics from '../../services/analytics'

const { withThemeProvider } = styles

const HEADER = {
  TITLE: 'TRANSACT ON LOOPNET WITH AUCTIONS BY TEN-X',
  DESCRIPTION: `It's time to supercharge your property's visibility and attract the high-caliber tenants and buyers you're looking for. Let us guide you through our industry-leading marketing strategies to elevate your listings and get your property sold faster.`,
  SIZE: '46',
}

const TransactOnLoopnet: React.FC = () => {
  return (
    <TransactContainer id="demoform">
      <Banner
        colorBg="#F4F4F3"
        header={HEADER.TITLE}
        description={HEADER.DESCRIPTION}
        descSize={HEADER.SIZE}
      />
      <ContentWrapper>
        <ImageCol xs={24} md={24} lg={12}>
          <StyledImage
            src={'/images/homepage/personwithIpad.png'}
            alt="Person with tablet"
          />
        </ImageCol>
        <FormCol xs={24} md={14} lg={12}>
          <FormContent style={formContainerStyle}>
            <>
              <FormHeader>LEARN MORE ABOUT AUCTION SOLUTIONS</FormHeader>
              <ContentText>
                If you are a commercial real estate owner or broker looking to
                transact, contact us at 888-770-7332, Mon-Fri 10am-8pm ET or
                submit this form.
              </ContentText>
              <iframe
                allowtransparency="true"
                frameBorder="0"
                height="700"
                style={{ border: 0, width: '100%' }}
                src={'https://go.ten-x.com/l/667613/2022-06-30/45njc'}
              />
            </>
          </FormContent>
        </FormCol>
      </ContentWrapper>
    </TransactContainer>
  )
}

const FormHeader = styled.div`
  text-align: left;
  font-size: 2rem;
  line-height: 1.2;
  max-width: 500px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    text-align: center;
    padding: 10px 0px;
  }
`

const ContentText = styled.div`
  text-align: left;
  padding: 20px 0px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    text-align: center;
    padding: 10px 0px;
  } ;
`

const TransactContainer = styled.div`
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 120rem;
  border-radius: 8px;
`

const FormContent = styled.div`
  padding: 20px;
`

const FormCol = styled(Col)`
  justify-content: center;
`

const ContentWrapper = styled(Row)`
  justify-content: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 100px;
  background-color: #fff;

  @media (max-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    display: flex;
    flex-direction: column;
  } ;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 700px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    margin-top: 30px;
  }
`

const ImageCol = styled(Col)`
  text-align: center;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    text-align: center;
  } ;
`

const formContainerStyle = {
  textAlign: 'left',
}

export default withThemeProvider(TransactOnLoopnet)
