import moment from 'moment'

import ACTION_TYPES from '../constants/actionTypes'

const defaultState = {
  role: 'information',
  isVisible: false,
  isCloseable: false,
}

export default (state, action) => {
  if (typeof state === 'undefined') {
    return defaultState
  }

  switch (action.type) {
    case ACTION_TYPES.SHARED_MESSAGE_UPDATE: {
      return Object.assign({}, action.value, { timeStamp: moment().unix() })
    }
    case ACTION_TYPES.SHARED_MESSAGE_CLOSE: {
      return Object.assign({}, state, {
        isVisible: false,
      })
    }
    default:
      return state
  }
}
