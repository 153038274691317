import React from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'antd'
import styled from '@emotion/styled'
import sanitizeHtml from 'sanitize-html'

import HelpTopic from './helpTopic'

const { Panel } = Collapse

const HelpSection = ({ section, topics, contents }) => {
  const pureContent = sanitizeHtml(section?.body?.processed)

  const renderPanelTitle = (title) => <HelpTopicTitle>{title}</HelpTopicTitle>

  return (
    <SectionArticleContainer>
      <ArticleContentContainer>
        <ArticleContent
          dangerouslySetInnerHTML={{
            __html: pureContent,
          }}
        />
        {section?.field_help_topics?.data?.length > 0 &&
          section.field_help_topics.data?.map((helpTopic) => (
            <Collapse key={helpTopic.id}>
              <PanelContainer
                header={renderPanelTitle(
                  topics[helpTopic.id]?.attributes?.title
                )}
              >
                <HelpTopic
                  key={helpTopic.id}
                  topic={topics[helpTopic.id]}
                  contents={contents}
                />
              </PanelContainer>
            </Collapse>
          ))}
      </ArticleContentContainer>
    </SectionArticleContainer>
  )
}

const SectionArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  font-size: 16px;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: row;
    margin: 0;
  }
`

const ArticleContentContainer = styled.div`
  color: rgba(136, 136, 136, 1);
  font-weight: 300;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const ArticleContent = styled.div``

const MemberObject = {
  title: PropTypes.string,
}

HelpSection.propTypes = {
  section: PropTypes.shape(MemberObject),
  topics: PropTypes.shape(MemberObject),
  contents: PropTypes.shape(MemberObject),
}

const PanelContainer = styled(Panel)`
  font-size: 18px;
`

const HelpTopicTitle = styled.h3`
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
`

export default HelpSection
