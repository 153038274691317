/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Collapse, Spin, Empty } from 'antd'
import sanitizeHtml from 'sanitize-html'
import styled from '@emotion/styled'
import { styles } from '@ten-x/fe-lib-ui2020'

import KNOWLEDGE_CENTER from '../../constants/knowledge_center'
import WPProvider from '../../data_providers/drupal'
import LeadForm from '../leadForm'
import analytics from '../../services/analytics'

import HelpSection from './helpSection'

const { Panel } = Collapse

const { withThemeProvider } = styles
export const HelpPage = () => {
  const [loading, setLoading] = useState(true)
  const [helpCenter, setHelpCenter] = useState()

  useEffect(() => {
    analytics.onPageLoadHelpCenter()
    setLoading(true)
    const getHelpCenter = async () => {
      const response = await WPProvider.getHelpCenter()
      setHelpCenter(response)
      setLoading(false)
    }

    getHelpCenter()
  }, [])

  const title = helpCenter?.title || ''
  const pureContent = sanitizeHtml(helpCenter?.body?.processed)

  const renderPanelTitle = (title) => (
    <HelpSectionTitle>{title}</HelpSectionTitle>
  )

  return (
    <HelpContainer>
      <Helmet>
        <title>{KNOWLEDGE_CENTER.HELP_CENTER_TITLE}</title>
        <meta
          name="description"
          content={KNOWLEDGE_CENTER.HELP_CENTER_DESCRIPTION}
        />
        <link rel="canonical" href={KNOWLEDGE_CENTER.HELP_CENTER_CANONICAL} />
      </Helmet>
      {loading ? (
        <Spin />
      ) : helpCenter ? (
        <>
          <TitleContainer
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(title.split('--')[0]),
            }}
          />
          <ContentContainer
            dangerouslySetInnerHTML={{
              __html: pureContent,
            }}
          />
          <HelpSectionsContainer>
            {helpCenter?.field_help_sections?.length > 0 &&
              helpCenter.field_help_sections.map((helpSection, idx) => (
                <Collapse key={helpSection.id} defaultActiveKey={[idx]}>
                  <PanelContainer
                    header={renderPanelTitle(helpSection?.title)}
                    key={idx}
                  >
                    <HelpSection
                      key={helpSection.id}
                      section={helpSection}
                      topics={helpCenter.field_help_topics}
                      contents={helpCenter.field_help_contents}
                    />
                  </PanelContainer>
                </Collapse>
              ))}
          </HelpSectionsContainer>
          <LeadFormMobile>
            <LeadForm />
          </LeadFormMobile>
          <LeadFormTablet>
            <LeadForm />
          </LeadFormTablet>
        </>
      ) : (
        <Empty description="Not Found" />
      )}
    </HelpContainer>
  )
}

const HelpContainer = styled.div`
  margin: 0 auto;
  max-width: 1100px;

  text-align: center;
  width: 100%;
`

const TitleContainer = styled.h1`
  color: rgba(89, 89, 89, 1);
  font-size: 30px;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
  }
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 50px;
  }
`
const ContentContainer = styled.div`
  color: rgba(136, 136, 136, 1);
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  padding: 0 10px;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 20px;
  }
`

const HelpSectionsContainer = styled.div`
  text-align: left;
  padding-bottom: 20px;
`

const HelpSectionTitle = styled.h2`
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
`

const PanelContainer = styled(Panel)`
  font-size: 18px;
`

const LeadFormMobile = styled.div`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: none;
  }
`

const LeadFormTablet = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    display: block;
  }
`

export default withThemeProvider(HelpPage)
